import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams } from 'use-query-params';
import IconCouponsActive from '../../assets/icon-sidebar-coupons-active.svg';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Button from '../../components/Form/Button';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import {
  COUPONS_SCREEN,
  DEFAULT_PAGE_SIZE,
  SMARTPAY_DOCUMENT_URL,
} from '../../constants';
import { useCouponsQuery } from '../../services/coupon';
import { useDataMode } from '../../utils/helper';
import styles from './CouponListScreen.module.scss';
import CouponRow from './CouponRow';
import CreateCouponModal from './CreateCouponModal';
import useViewMode, { hasPermission } from '../../hooks/use-view-mode';

const screen = COUPONS_SCREEN;

const CouponListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const [isShowModal, setIsShowModal] = useState(false);

  const { t } = useTranslation('translation');
  const { merchantId, roles } = useViewMode();
  const { test: isTestDataMode } = useDataMode();
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;
  const { data, isLoading, refetch } = useCouponsQuery({
    merchant: merchantId,
    offset,
    test: isTestDataMode,
    limit: DEFAULT_PAGE_SIZE,
  });

  const coupons = data?.data.filter(
    (coupon) => !(coupon.sponsored && !coupon.active)
  );
  const hasNextPage = !!data?.nextOffset;

  const createCoupon = () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Create Coupon',
    });

    setIsShowModal(true);
  };

  return (
    <Layout isLoading={!coupons && isLoading}>
      <div className={styles['top-bar']}>
        <h1>{t('coupon-listing.title')}</h1>
        {hasPermission(roles, 'CouponsEditor') && (
          <Button
            id="btn_create_coupon"
            label={
              isTestDataMode
                ? t('coupon-listing.create-a-test-coupon')
                : t('coupon-listing.create-a-coupon')
            }
            variant="outline"
            size="small"
            onClick={createCoupon}
          />
        )}
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.coupon}>
              {t('coupon-listing.table-header.coupon')}
            </th>
            <th>{t('coupon-listing.table-header.condition')}</th>
            <th className={styles.redemptions}>
              {t('coupon-listing.table-header.number-of-redemptions')}
            </th>
            <th>{t('coupon-listing.table-header.date-of-expiry')}</th>
          </tr>
        </thead>
        <tbody>
          {coupons &&
            coupons.length > 0 &&
            coupons.map((coupon) => (
              <CouponRow key={coupon.id} coupon={coupon} />
            ))}
        </tbody>
      </table>
      {!coupons?.length ? (
        <EmptyStateView
          src={IconCouponsActive}
          title={isTestDataMode ? t('No test coupon') : t('No coupon')}
        >
          <>
            <span>
              <Trans
                i18nKey={
                  isTestDataMode
                    ? 'coupon-listing.empty-state.test.desc'
                    : 'coupon-listing.empty-state.desc'
                }
              />
            </span>
            <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
            {hasPermission(roles, 'CouponsEditor') && (
              <Button
                className={styles['create-coupon']}
                size="small"
                label={
                  isTestDataMode
                    ? t('coupon-listing.create-a-test-coupon')
                    : t('coupon-listing.create-a-coupon')
                }
                onClick={createCoupon}
              />
            )}
          </>
        </EmptyStateView>
      ) : (
        <PaginationView
          hasPrevPage={offset > 0}
          hasNextPage={hasNextPage}
          screen={screen}
          onPrevClick={() =>
            setQuery(
              {
                offset: offset - DEFAULT_PAGE_SIZE,
              },
              'replaceIn'
            )
          }
          onNextClick={() =>
            setQuery(
              {
                offset: data?.nextOffset,
              },
              'replaceIn'
            )
          }
        />
      )}

      {isShowModal && (
        <CreateCouponModal
          type="merchant"
          merchantId={merchantId}
          hide={() => {
            refetch();
            setIsShowModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default CouponListScreen;
