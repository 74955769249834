import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import FeeView from './FeeView';
import styles from './PaymentsScreen.module.scss';
import SettingNavBar from './SettingNavBar';
import Time from '../../components/Time/Time';
import {
  useMerchantBankAccountQuery,
  useMerchantFeePlanQuery,
  useMerchantPayoutScheduleQuery,
  useMerchantPlanFeesQuery,
} from '../../hooks/use-merchant-queries';

const PaymentsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');
  const { data: bankAccount } = useMerchantBankAccountQuery();
  const { data: planFeesData } = useMerchantPlanFeesQuery();
  const { data: feePlan } = useMerchantFeePlanQuery();
  const { data: payoutSchedule } = useMerchantPayoutScheduleQuery();

  const planFees = planFeesData?.data;

  return (
    <Layout title={t('settings.title')} className={styles.layout}>
      <SettingNavBar />
      <div className={styles.container}>
        {bankAccount && (
          <section>
            <h2>{t('settings.payments.bank-account.title')}</h2>
            {bankAccount.country && bankAccount.country !== 'jp' && (
              <TextInput
                label={t('settings.payments.bank-account.country')}
                name="country"
                variant="secondary"
                value={t(`country.${bankAccount.country}`)}
                readOnly
              />
            )}
            <TextInput
              label={t('settings.payments.bank-account.bank-account-name')}
              name="name"
              variant="secondary"
              value={bankAccount.name}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.bank-code')}
              name="bank code"
              variant="secondary"
              value={bankAccount.bankCode}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.branch-number')}
              name="branch_number"
              variant="secondary"
              value={bankAccount.branchCode}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.account-number')}
              name="account_number"
              variant="secondary"
              value={bankAccount.accountNumber.replaceAll('X', '•')}
              readOnly
            />
            {bankAccount.accountType && (
              <TextInput
                label={t('settings.payments.bank-account.account-type')}
                name="account_type"
                variant="secondary"
                value={bankAccount.accountType}
                readOnly
              />
            )}
            <TextInput
              label={t('settings.payments.bank-account.currency')}
              name="currency"
              variant="secondary"
              value={bankAccount.currency}
              readOnly
            />
          </section>
        )}
        {!planFees && feePlan && (
          <section className={styles.fees}>
            <h2>{t('settings.payments.fee.title')}</h2>
            <div>
              {feePlan.fees.length > 0 ? (
                feePlan.fees.map((fee, index) => {
                  let desc = '';

                  if (feePlan.fees.length > 1) {
                    desc =
                      fee.limit.lower === 0
                        ? `最初の${Math.round(
                            (fee.limit.upper + 1) / 10000
                          )}万円の残高`
                        : `${Math.round(
                            fee.limit.lower / 10000
                          )}万円を超える残高`;
                  }

                  return (
                    <FeeView
                      key={index}
                      fee={fee.rate}
                      fixedFee={fee.fixedAmount}
                      desc={desc}
                    />
                  );
                })
              ) : (
                <FeeView hidden />
              )}
            </div>
          </section>
        )}
        {payoutSchedule?.schedule && (
          <section className={styles['payout-schedule']}>
            <h2>{t('settings.payments.payout-schedule.title')}</h2>

            <label>
              {t('settings.payments.payout-schedule.payout-schedule')}
            </label>
            <p>{t(payoutSchedule.schedule)}</p>
            <label>{t('settings.payments.payout-schedule.next-payment')}</label>
            <div>
              <p className={styles.period}>
                <Time
                  dateTime={payoutSchedule.period.start}
                  formatStr={t('format.MMMdd', 'MM/dd')}
                />
                {t('format.periodTo')}
                <Time
                  dateTime={payoutSchedule.period.end}
                  formatStr={t('format.MMMdd', 'MM/dd')}
                />
              </p>
              <p>
                <Trans
                  i18nKey="settings.payments.payout-schedule.on-date"
                  components={[
                    <Time
                      dateTime={payoutSchedule.period.nextPaymentAt}
                      formatStr={t('format.yyyyMMMdd', 'yyyy/MM/dd')}
                    />,
                  ]}
                />
              </p>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default PaymentsScreen;
