import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  BatchPlanFee,
  PaymentMethodCategory,
  PaymentPlan,
  PlanFee,
} from '../../types/plan-fee';

import installments1Src from '../../assets/installments-1.svg';
import installments3Src from '../../assets/installments-3.svg';
import installments6Src from '../../assets/installments-6.svg';
import installments12Src from '../../assets/installments-12.svg';
import cardsSrc from '../../assets/pm/cards.svg';
import bankSrc from '../../assets/pm/bank.svg';

import styles from './PlanFees.module.scss';

interface Props {
  data?: PlanFee[];
  onClick?: () => void;
  className?: string;
}

export const CATEGORY_SRC = {
  CardBased: cardsSrc,
  BankBased: bankSrc,
  '': '',
};

export const Per = <span className={styles.symbol}>%</span>;
export const Yen = <span className={styles.symbol}>¥</span>;
export const Add = <span className={styles.plus}>+</span>;
export const Na = <span className={styles.na}>Not Avaiable</span>;

export const findPlanFee = (
  data: BatchPlanFee[] | undefined,
  paymentPlan: PaymentPlan,
  paymentMethodCategory: PaymentMethodCategory
) => {
  return data?.find(
    (option) =>
      option?.paymentPlan === paymentPlan &&
      option?.paymentMethodCategory === paymentMethodCategory
  );
};

export const findPaymentOption = (
  data: BatchPlanFee[] | undefined,
  paymentPlan: PaymentPlan
) => {
  const card = findPlanFee(data, paymentPlan, 'CardBased');
  const bank = findPlanFee(data, paymentPlan, 'BankBased');

  return { card, bank };
};

const PlanFeeValue = ({ planFee }: { planFee?: BatchPlanFee }) => {
  return (
    <div className={styles.fee}>
      {planFee ? Math.floor(planFee.ratedFee * 10000) / 100 : '-'}
      {Per}
      {Add}
      {Yen}
      {planFee ? planFee.fixedFee : '-'}
    </div>
  );
};

const PlanFees = ({ data = [], onClick, className }: Props) => {
  const { t } = useTranslation();
  const [SmartpayOne, PayInThree, SmartpaySix, SmartpayTwelve] = useMemo(
    () => [
      findPaymentOption(data, 'SmartpayOne'),
      findPaymentOption(data, 'PayInThree'),
      findPaymentOption(data, 'SmartpaySix'),
      findPaymentOption(data, 'SmartpayTwelve'),
    ],
    [data]
  );

  return (
    <div className={cx(styles.options, className)} onClick={onClick}>
      <div className={styles.option}>
        <img
          src={installments1Src}
          alt={t('payment-options.1-installment')}
          width="56"
          height="56"
        />
        <hr />
        <div className={styles['pm-container']}>
          <img src={cardsSrc} alt="Card" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpayOne.card} />
          </div>
        </div>
        <div className={styles['pm-container']}>
          <img src={bankSrc} alt="Bank" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpayOne.bank} />
          </div>
        </div>
      </div>
      <div className={styles.option}>
        <img
          src={installments3Src}
          alt={t('payment-options.3-installments')}
          width="56"
          height="56"
        />
        <hr />
        <div className={styles['pm-container']}>
          <img src={cardsSrc} alt="Card" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={PayInThree.card} />
          </div>
        </div>
        <div className={styles['pm-container']}>
          <img src={bankSrc} alt="Bank" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={PayInThree.bank} />
          </div>
        </div>
      </div>
      <div className={styles.option}>
        <img
          src={installments6Src}
          alt={t('payment-options.6-installments')}
          width="56"
          height="56"
        />
        <hr />
        <div className={styles['pm-container']}>
          <img src={cardsSrc} alt="Card" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpaySix.card} />
          </div>
        </div>
        <div className={styles['pm-container']}>
          <img src={bankSrc} alt="Bank" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpaySix.bank} />
          </div>
        </div>
      </div>
      <div className={styles.option}>
        <img
          src={installments12Src}
          alt={t('payment-options.12-installments')}
          width="56"
          height="56"
        />
        <hr />
        <div className={styles['pm-container']}>
          <img src={cardsSrc} alt="Card" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpayTwelve.card} />
          </div>
        </div>
        <div className={styles['pm-container']}>
          <img src={bankSrc} alt="Bank" width="32" height="20" />
          <div className={styles['fee-box']}>
            <PlanFeeValue planFee={SmartpayTwelve.bank} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanFees;
