import { useParams } from '@reach/router';
import { useAccountInfoQuery } from '../services/setting';
import { Branch, Role } from '../types/setting';

export type Mode = 'corporate' | 'corporate-merchant' | 'merchant';
export type ViewMode = 'corporate' | 'merchant';

interface ViewModeData {
  mode: Mode | undefined;
  viewMode: ViewMode | undefined;
  corporateAccountName?: string;
  merchantId?: string;
  merchant?: Branch;
  roles?: Role[];
  base: string;
}

export const hasPermission = (roles: Role[] = [], permission: string) => {
  if (roles.length === 0) {
    return false;
  }

  if (permission === 'CorporateOwner') {
    return roles.includes('CorporateOwner');
  }

  if (permission === 'CorporateManager') {
    return (
      roles.includes('CorporateOwner') || roles.includes('CorporateManager')
    );
  }

  if (permission === 'Manager') {
    return (
      roles.includes('CorporateOwner') ||
      roles.includes('CorporateManager') ||
      roles.includes('Manager')
    );
  }

  if (
    ['Orders', 'Refunds', 'Coupons', 'PayLinks', 'Payouts', 'Settings'].find(
      (v) => permission.indexOf(v) >= 0
    )
  ) {
    if (
      roles.includes('CorporateOwner') ||
      roles.includes('CorporateManager') ||
      roles.includes('Manager')
    ) {
      return true;
    }

    if (roles.find((role) => role.indexOf(permission) === 0)) {
      return true;
    }
  }

  return !!roles.find((role) => role === permission);
};

const useViewMode = (): ViewModeData => {
  const { merchantId } = useParams();
  const { data: accountInfo } = useAccountInfoQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const merchants = accountInfo?.merchants || [];
  const merchant =
    merchants.length === 1
      ? merchants[0]
      : merchants.find((m) => m.id === merchantId);

  if (!accountInfo) {
    return {
      mode: undefined,
      viewMode: undefined,
      base: '',
    };
  }

  // corporate merchant
  if (accountInfo.corporateAccountsFeatureEnabled && merchantId) {
    return {
      mode: 'corporate-merchant',
      viewMode: 'merchant',
      corporateAccountName: accountInfo.corporateAccountName,
      merchantId: merchantId as string,
      merchant,
      roles: [...accountInfo.corporateAccountRoles, ...(merchant?.roles || [])],
      base: `/m/${merchantId}`,
    };
  }

  // corporate
  if (accountInfo.corporateAccountsFeatureEnabled) {
    return {
      mode: 'corporate',
      viewMode: 'corporate',
      corporateAccountName: accountInfo.corporateAccountName,
      roles: accountInfo.corporateAccountRoles,
      base: '/c',
    };
  }

  // merchant only
  return {
    mode: 'merchant',
    viewMode: 'merchant',
    corporateAccountName: accountInfo?.corporateAccountName,
    merchantId: merchantId as string,
    merchant,
    roles: merchant?.roles,
    base: '',
  };
};

export default useViewMode;
