import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Mixpanel from '@smartpay/mixpanel';
import { useState } from 'react';

import Button from '../../components/Form/Button';
import { USER_CREATION_SCREEN } from '../../constants';
import {
  useCorporateUserQuery,
  useDeleteCorporateUserMutation,
  useDeleteUserMutation,
  useUserQuery,
} from '../../services/users';

import styles from './UserModal.module.scss';
import { useForm } from 'react-hook-form';
import useViewMode from '../../hooks/use-view-mode';

interface Props {
  type?: 'branch' | 'merchant' | 'corporate';
  id: string;
  hide: () => void;
}

const DeleteUserModal = ({ type = 'corporate', id, hide }: Props) => {
  const { t } = useTranslation('translation');
  const { merchantId } = useViewMode();

  const [isProcessing, setIsProcessing] = useState(false);
  const { data: merchantUser } = useUserQuery(
    { id: id as string, merchant: merchantId },
    { skip: type !== 'merchant' }
  );
  const { data: corporateUser } = useCorporateUserQuery(
    { id: id as string },
    { skip: type !== 'corporate' }
  );
  const userData = type === 'corporate' ? corporateUser : merchantUser;
  const [deleteCorporateUser] = useDeleteCorporateUserMutation();
  const [deleteMerchantUser] = useDeleteUserMutation();
  const deleteUser =
    type === 'corporate' ? deleteCorporateUser : deleteMerchantUser;

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    Mixpanel.trackAction({
      screen: USER_CREATION_SCREEN,
      action: 'Click',
      itemName: 'Delete User',
    });

    setIsProcessing(true);

    try {
      const result = await deleteUser({ id, merchant: merchantId });

      if ('error' in result) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error((result.error as any).data || 'unexpected_error');
      }

      hide();
    } catch (error) {
      // TODO: set error msg
      setIsProcessing(false);
    }
  };

  return (
    <div className={styles['modal-background']}>
      <div className={cx(styles.modal)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={cx(styles.single)}>
            <div className="header">
              <h3 id="create-branch-modal-title">
                {t(`user-modal.title.delete`)}
              </h3>
              <p>{t('user-modal.label.delete-user')}</p>
            </div>
            <div className={styles.row}>
              <div className={styles.username}>{userData?.username}</div>
            </div>
          </div>
          <div className={cx(styles.row, styles.footer)}>
            <Button
              type="submit"
              variant="outline"
              size="small"
              label={t(`user-modal.button.delete`)}
              processing={isProcessing}
            />
            <Button
              type="button"
              onClick={hide}
              size="small"
              label={t('cancel-btn')}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteUserModal;
