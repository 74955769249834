import { Link, LinkProps, useLocation } from '@reach/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';

import DataToggle from '../Toggle/DataToggle';
import useViewMode, { hasPermission } from '../../hooks/use-view-mode';
import OutsideClickHandler from 'react-outside-click-handler';
import ProfilePopup, { PROFILE_POPUP_SCREEN } from '../Popup/ProfilePopup';
import { useFeaturesQuery } from '../../services/setting';

import srcLogo from '../../assets/logotype-white.svg';
import srcCouponActive from '../../assets/icon-sidebar-coupons-active.svg';
import srcCoupon from '../../assets/icon-sidebar-coupons.svg';
import srcOrderActive from '../../assets/icon-sidebar-order-active.svg';
import srcOrder from '../../assets/icon-sidebar-order.svg';
import srcPaymentLinkActive from '../../assets/icon-sidebar-payment-link-active.svg';
import srcPaymentLink from '../../assets/icon-sidebar-payment-link.svg';
import srcPayoutActive from '../../assets/icon-sidebar-payout-active.svg';
import srcPayout from '../../assets/icon-sidebar-payout.svg';
import srcUsersActive from '../../assets/icon-sidebar-users-active.svg';
import srcUsers from '../../assets/icon-sidebar-users.svg';
import srcInquiry from '../../assets/icon-sidebar-inquiry.svg';
import srcProfileActive from '../../assets/icon-sidebar-profile-active.svg';
import srcProfile from '../../assets/icon-sidebar-profile.svg';
import srcSettings from '../../assets/icon-settings.svg';

import styles from './Aside.module.scss';

export const SideBarItem = ({
  id,
  src,
  title,
}: {
  id: string;
  src: string;
  title?: string;
}) => (
  <>
    <img src={src} width={24} height={24} alt="" />
    {!!title && <span id={id}>{title}</span>}
  </>
);

export const SideBarLink = (
  props: React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement> & {
      id: string;
      src: string;
      title?: string;
      active_src: string;
    }
) => {
  const { id, src, active_src, title } = props;

  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = isCurrent || isPartiallyCurrent;

        return {
          className: isActive ? styles.active : null,
          children: (
            <SideBarItem
              id={id}
              src={isActive ? active_src : src}
              title={title}
            />
          ),
        };
      }}
    />
  );
};

const Aside = () => {
  const { t } = useTranslation('translation');
  const { base, roles, merchantId } = useViewMode();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const { href } = useLocation();
  const { data } = useFeaturesQuery();

  const feature = data?.merchants.find(
    (m) => !merchantId || m.id === merchantId
  )?.feature;

  const isSettings = isShowPopup || href.includes('settings/');

  return (
    <aside className={styles.aside}>
      <Link to="/">
        <img src={srcLogo} height={24} alt="Smartpay" />
      </Link>
      <nav>
        <section className={styles.top}>
          {hasPermission(roles, 'Orders') && (
            <SideBarLink
              id="menu_orders"
              to={`${base}/orders`}
              src={srcOrder}
              active_src={srcOrderActive}
              title={t('sidebar-item.orders')}
            />
          )}
          {hasPermission(roles, 'Payouts') && (
            <SideBarLink
              id="menu_payouts"
              to={`${base}/payouts`}
              src={srcPayout}
              active_src={srcPayoutActive}
              title={t('sidebar-item.payouts')}
            />
          )}
          {hasPermission(roles, 'Coupons') && (
            <SideBarLink
              id="menu_coupons"
              to={`${base}/coupons`}
              src={srcCoupon}
              active_src={srcCouponActive}
              title={t('sidebar-item.coupons')}
            />
          )}
          {feature?.paymentLink && hasPermission(roles, 'PayLinks') && (
            <SideBarLink
              id="menu_payment_links"
              to={`${base}/payment-links`}
              src={srcPaymentLink}
              active_src={srcPaymentLinkActive}
              title={t('sidebar-item.payment-links')}
            />
          )}
          {hasPermission(roles, 'Manager') && (
            <SideBarLink
              id="menu_payment_users"
              to={`${base}/users`}
              src={srcUsers}
              active_src={srcUsersActive}
              title={t('sidebar-item.users')}
            />
          )}
          {hasPermission(roles, 'Settings') && (
            <SideBarLink
              id="menu_payment_settings"
              to={`${base}/settings`}
              src={srcSettings}
              active_src={srcSettings}
              title={t('sidebar-item.settings')}
            >
              {t('sidebar-item.settings')}
            </SideBarLink>
          )}
        </section>
        <section className={styles.bot}>
          <div className={styles.block}>
            <SideBarLink
              id="menu_inquiry"
              to={`${base}/inquiry`}
              src={srcInquiry}
              active_src={srcInquiry}
              title={t('sidebar-item.inquiry')}
            />
          </div>
          <div className={styles.block}>
            <button
              id="btn_profile"
              type="button"
              className={cx(styles.profile)}
              onClick={() => setIsShowPopup(!isShowPopup)}
            >
              <SideBarItem
                id="menu_profile"
                src={isSettings ? srcProfileActive : srcProfile}
                title={t('sidebar-item.profile')}
              />
            </button>
            {isShowPopup && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  Mixpanel.trackAction({
                    screen: PROFILE_POPUP_SCREEN,
                    action: 'Click',
                    itemName: 'Dismiss',
                  });

                  if (isShowPopup) {
                    setIsShowPopup(false);
                  }
                }}
              >
                <ProfilePopup />
              </OutsideClickHandler>
            )}
          </div>
          <DataToggle className={styles.block} />
        </section>
      </nav>
    </aside>
  );
};

export default Aside;
