import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { isValid as isValidDate } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';

import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import TextInput from '../../components/Form/TextInput';
import { PROMOTION_CODE_CREATION_SCREEN } from '../../constants';
import { ERROR_PROMOTION_CODE_EXISTS } from '../../redux/error-codes';
import { useDataMode } from '../../utils/helper';
import {
  useCreateCorporatePromotionCodeMutation,
  useCreatePromotionCodeMutation,
} from '../../services/coupon';
import { CreatePromotionCodeInput } from '../../types/coupon';

import styles from './CreatePromotionCodeModal.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4456dd',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #dfe1e5', // default
            },
            '&:hover fieldset': {
              border: '1px solid #dfe1e5', // hover
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4456dd', // focus
            },
          },
        },
      },
    },
  },
});

const screen = PROMOTION_CODE_CREATION_SCREEN;

const schema = z
  .object({
    code: z.string(),
    hasExpiryDate: z.boolean(),
    expiresAt: z.coerce.number().optional(),
    hasMaxRedemptions: z.boolean(),
    maxRedemptionCount: z.coerce.number().optional(),
    firstTimeTransaction: z.boolean(),
    onePerCustomer: z.boolean(),
    hasMinimumAmount: z.boolean(),
    minimumAmount: z.coerce.number().optional(),
  })

  .superRefine((data, ctx) => {
    if (data.hasExpiryDate) {
      if (!isValidDate(data.expiresAt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['expiresAt'],
          message: `invalid`,
        });
      }
    }
  });

const CreatePromotionCodeModal = ({
  type,
  couponId,
  merchantId,
  hide,
}: {
  type: 'corporate' | 'merchant';
  couponId: string;
  merchantId?: string;
  hide: () => void;
}) => {
  const { t, i18n } = useTranslation('translation');
  const { test: isTestMode } = useDataMode();

  const [
    createMerchantPromotionCode,
    { isLoading: isCreatingMerchantPromotionCode },
  ] = useCreatePromotionCodeMutation();
  const [
    createCoporatePromotionCode,
    { isLoading: isCreatingCorporatePromotionCode },
  ] = useCreateCorporatePromotionCodeMutation();

  const createPromotionCode =
    type === 'merchant'
      ? createMerchantPromotionCode
      : createCoporatePromotionCode;
  const isLoading =
    isCreatingMerchantPromotionCode || isCreatingCorporatePromotionCode;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { isValid, errors },
  } = useForm<CreatePromotionCodeInput>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      code: '',
      hasExpiryDate: false,
      expiresAt: undefined,
      hasMaxRedemptions: false,
      maxRedemptionCount: undefined,
      firstTimeTransaction: false,
      onePerCustomer: false,
      hasMinimumAmount: false,
      minimumAmount: undefined,
    },
  });

  const watchHasMaxRedemptions = watch('hasMaxRedemptions');
  const watchHasExpiryDate = watch('hasExpiryDate');
  const watchExpiresAt = watch('expiresAt');
  const watchHasMinimumAmount = watch('hasMinimumAmount');

  const watchFirstTimeTransaction = watch('firstTimeTransaction');
  const watchOnePerCustomer = watch('onePerCustomer');

  const onSubmit = useCallback(
    async (data: CreatePromotionCodeInput) => {
      try {
        const result = await createPromotionCode({
          merchant: merchantId,
          input: {
            coupon: couponId,
            code: data.code,
            active: true,
            test: isTestMode,
            firstTimeTransaction: data.firstTimeTransaction,
            onePerCustomer: data.onePerCustomer,
            currency: 'JPY',
            expiresAt: data.hasExpiryDate ? data.expiresAt : undefined,
            maxRedemptionCount: data.hasMaxRedemptions
              ? data.maxRedemptionCount
              : undefined,
            minimumAmount: data.hasMinimumAmount
              ? data.minimumAmount
              : undefined,
          },
        });

        if ('error' in result) {
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (result.error as any).data.errorCode || 'unexpected_error'
          );
        }

        hide();
      } catch (error) {
        if (error instanceof Error) {
          switch (error.message) {
            case ERROR_PROMOTION_CODE_EXISTS:
              setError('code', {
                type: 'custom',
                message: t('create-promotion-code-modal.error.code-exists', {
                  code: data.code,
                }),
              });
              break;

            default:
              setError('root', { type: 'custom', message: error.message });
          }
        }
      }
    },
    [createPromotionCode, merchantId, couponId, isTestMode, hide, setError, t]
  );

  return (
    <div className={styles['modal-background']}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cx(styles.modal, isTestMode ? styles.test : null)}
      >
        <div>
          <h3 id="create-promotion-code-modal-title">
            {t('create-promotion-code-modal.title')}
          </h3>
          <p>
            <Trans i18nKey="create-promotion-code-modal.desc" />
          </p>
        </div>
        <div className={styles.body}>
          <TextInput
            autoFocus
            label={t('create-promotion-code-modal.code.label')}
            placeholder={t('create-promotion-code-modal.code.placeholder')}
            {...register('code')}
            errorMessage={errors.code?.message}
          />
          <Checkbox
            label={t(
              'create-promotion-code-modal.first-time-transaction.label'
            )}
            {...register('firstTimeTransaction')}
            checked={watchFirstTimeTransaction}
          />
          <Checkbox
            label={t('create-promotion-code-modal.one-per-customer.label')}
            {...register('onePerCustomer')}
            checked={watchOnePerCustomer}
          />
          <Checkbox
            label={t('create-promotion-code-modal.max-redemption.label')}
            {...register('hasMaxRedemptions')}
            checked={watchHasMaxRedemptions}
          />
          {watchHasMaxRedemptions && (
            <TextInput
              autoFocus
              rightText="回"
              {...register('maxRedemptionCount')}
              errorMessage={errors.maxRedemptionCount?.message}
            />
          )}

          <Checkbox
            label={t('create-promotion-code-modal.expiry-date.label')}
            {...register('hasExpiryDate')}
            checked={watchHasExpiryDate}
          />

          {watchHasExpiryDate && (
            <div>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={i18n.language === 'ja' ? ja : enUS}
                >
                  <DateTimePicker
                    value={watchExpiresAt || ''}
                    onChange={(value) =>
                      setValue('expiresAt', value?.getTime(), {
                        shouldValidate: true,
                      })
                    }
                    ampm={false}
                    minDateTime={new Date()}
                    inputFormat="yyyy/MM/dd HH:mm"
                    renderInput={(params) => (
                      <TextField autoFocus {...params} />
                    )}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </div>
          )}
          <Checkbox
            label={t('create-promotion-code-modal.minimum-amount.label')}
            {...register('hasMinimumAmount')}
            checked={watchHasMinimumAmount}
          />
          {watchHasMinimumAmount && (
            <TextInput
              autoFocus
              rightText="JPY"
              {...register('minimumAmount')}
              errorMessage={errors.minimumAmount?.message}
            />
          )}
        </div>

        <div className={styles['modal-footer']}>
          <Button
            id="btn_create_promotion_code_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            type="button"
            onClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Create Promotion Code Cancel',
              });

              hide();
            }}
          />
          <Button
            id="btn_create_promotion_code_confirm"
            label={t('create-promotion-code-modal.add-promotion-code-btn')}
            size="small"
            type="submit"
            disabled={!isValid}
            processing={isLoading}
          />
        </div>
        {!!errors.root && (
          <div className={styles['error-wrapper']}>
            <p>{t([`error.${errors.root}`, errors.root.message as string])}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreatePromotionCodeModal;
