import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import { useTranslation } from 'react-i18next';

import Capsule from '../../components/Capsule/Capsule';
import { ORDERS_SCREEN } from '../../constants';
import Time from '../../components/Time/Time';
import { Branch } from '../../types/setting';

import styles from './BranchRow.module.scss';

const BranchRow = ({ branch }: { branch: Branch }) => {
  const { t } = useTranslation('translation');

  return (
    <tr
      className={styles.order}
      onClick={() => {
        Mixpanel.trackAction({
          screen: ORDERS_SCREEN,
          action: 'Click',
          itemName: 'Order',
        });
        navigate(`/m/${branch.id}/orders`);
      }}
    >
      <td>
        <Capsule
          title={t(`branch.status.${branch.status}`)}
          status={branch.status}
        />
      </td>

      <td>{branch?.name}</td>
      <td>{branch?.id}</td>
      <td>
        <Time
          dateTime={branch.createdAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
        />
      </td>
    </tr>
  );
};

export default BranchRow;
