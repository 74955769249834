import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import { API_BASE, DEFAULT_PAGE_SIZE } from '../constants';
import { Payout } from '../types/payout';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

export interface GetPayoutsParams extends TestParams, PageParams {
  merchant?: string;
  statuses?: string;
}

export interface GetPayoutParams {
  id: string;
  merchant?: string;
}

export const payoutAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    payouts: builder.query<ListResponse<Payout>, GetPayoutsParams>({
      query: (params) => {
        return {
          url: `/me/merchant/payouts`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    corporatePayouts: builder.query<ListResponse<Payout>, GetPayoutsParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/payouts`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    payout: builder.query<Payout, GetPayoutParams>({
      query: ({ id, merchant }) => ({
        url: `/me/merchant/payouts/${id}`,
        params: { merchant },
      }),
    }),
    payoutReportDownloadLink: builder.query<
      { downloadLink: string },
      GetPayoutParams
    >({
      query: ({ id, merchant }) => ({
        url: `${API_BASE}/reporting/payout-reports/${id}/download-link/create`,
        method: 'POST',
        params: { merchant },
      }),
      serializeQueryArgs: (args) => {
        // TTL of the link is 60mins, So change the cache key every 50mins
        return (
          defaultSerializeQueryArgs(args) +
          Math.floor(new Date().getTime() / 1000 / 60 / 50)
        );
      },
    }),
    payoutStatementDownloadLink: builder.query<
      { downloadLink: string },
      GetPayoutParams
    >({
      query: ({ id, merchant }) => ({
        url: `${API_BASE}/reporting/payout-reports/${id}/statement-download-link/create`,
        method: 'POST',
        params: { merchant },
      }),
      serializeQueryArgs: (args) => {
        // TTL of the link is 60mins, So change the cache key every 50mins
        return (
          defaultSerializeQueryArgs(args) +
          Math.floor(new Date().getTime() / 1000 / 60 / 50)
        );
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePayoutsQuery,
  useCorporatePayoutsQuery,
  usePayoutQuery,
  usePayoutReportDownloadLinkQuery,
  useLazyPayoutReportDownloadLinkQuery,
  usePayoutStatementDownloadLinkQuery,
  useLazyPayoutStatementDownloadLinkQuery,
} = payoutAPI;
