import { AccountInfo } from '../types/setting';
import { User } from '../types/user';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
};

export interface GetUsersParams extends TestParams, PageParams {
  merchant?: string;
}

export interface GetUserParams {
  merchant?: string;
  id: string;
}

export interface CreateUserPayload {
  merchant?: string;
  emailAddress: string;
  roles: string[];
}

export const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    users: builder.query<ListResponse<User>, GetUsersParams>({
      query: (params) => {
        return {
          url: `/me/merchant/users`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    user: builder.query<AccountInfo, GetUserParams>({
      query: (params) => {
        return {
          url: `/me/merchant/users/${params.id}`,
          params: {
            merchant: params.merchant,
          },
        };
      },
    }),
    upsertUser: builder.mutation<User, CreateUserPayload>({
      query: (data) => {
        return {
          url: `/me/merchant/users`,
          method: 'POST',
          params: {
            merchant: data.merchant,
          },
          data: {
            emailAddress: data.emailAddress,
            roles: data.roles,
          },
        };
      },
    }),
    deleteUser: builder.mutation<void, GetUserParams>({
      query: (params) => {
        return {
          url: `/me/merchant/users/${params.id}`,
          method: 'DELETE',
          params: {
            merchant: params.merchant,
          },
        };
      },
    }),
    corporateUsers: builder.query<ListResponse<User>, GetUsersParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/users`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    corporateUser: builder.query<AccountInfo, GetUserParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/users/${params.id}`,
        };
      },
    }),
    upsertCorporateUser: builder.mutation<User, CreateUserPayload>({
      query: (data) => {
        return {
          url: `/me/corporate-account/users`,
          method: 'POST',
          data: {
            emailAddress: data.emailAddress,
            roles: data.roles,
          },
        };
      },
    }),
    deleteCorporateUser: builder.mutation<void, GetUserParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/users/${params.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useUsersQuery,
  useUserQuery,
  useCorporateUsersQuery,
  useCorporateUserQuery,
  useUpsertUserMutation,
  useDeleteUserMutation,
  useUpsertCorporateUserMutation,
  useDeleteCorporateUserMutation,
} = userAPI;
