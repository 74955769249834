import cx from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMode } from '../utils/helper';
import Loading from './Loading';
import Header from './Header/Header';
import Aside from './Aside/Aside';
import CorporateAside from './CorporateAside/CorporateAside';
import useViewMode from '../hooks/use-view-mode';

import srcInfo from '../assets/icon-info.svg';

import styles from './Layout.module.scss';

const Strip = ({ title }: { title: string }) => {
  return (
    <div className={styles['strip-container']}>
      <div className={styles.strip}>
        <span>
          <img src={srcInfo} width={16} height={16} alt="" />
          {title}
        </span>
      </div>
    </div>
  );
};

const Layout = ({
  className,
  mainClassName,
  children,
  title = '',
  isLoading = false,
}: {
  className?: string;
  mainClassName?: string;
  children?: ReactNode;
  title?: string;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const { viewMode } = useViewMode();
  const { test: isTestDataMode } = useDataMode();

  return (
    <div className={cx(styles.root, styles[viewMode || 'default'], className)}>
      {viewMode === 'corporate' ? <CorporateAside /> : <Aside />}
      {viewMode !== 'corporate' && <Header className={styles.header} />}
      {isTestDataMode && <Strip title={t('strip-test-mode-desc')} />}
      {isLoading ? (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      ) : (
        <main className={cx(mainClassName)}>
          {title && <h1>{title}</h1>}
          {children}
        </main>
      )}
    </div>
  );
};

export default Layout;
