import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams } from 'use-query-params';

import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Button from '../../components/Form/Button';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import {
  COUPONS_SCREEN,
  DEFAULT_PAGE_SIZE,
  SMARTPAY_DOCUMENT_URL,
} from '../../constants';
import { useCorporateCouponsQuery } from '../../services/coupon';
import { useDataMode } from '../../utils/helper';
import CouponRow from './CouponRow';
import CreateCouponModal from './CreateCouponModal';
import CorporateNav from '../../components/CorporateNav/CorporateNav';
import useViewMode from '../../hooks/use-view-mode';

import IconCouponsActive from '../../assets/icon-sidebar-coupons-active.svg';

import styles from './CouponListScreen.module.scss';

const screen = COUPONS_SCREEN;

const CorporateCouponListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');
  const { corporateAccountName } = useViewMode();
  const { test: isTestDataMode } = useDataMode();

  const [isShowModal, setIsShowModal] = useState(false);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;
  const { data, isLoading, refetch } = useCorporateCouponsQuery({
    offset,
    test: isTestDataMode,
    limit: DEFAULT_PAGE_SIZE,
  });

  const coupons = data?.data.filter(
    (coupon) => !(coupon.sponsored && !coupon.active)
  );
  const hasNextPage = !!data?.nextOffset;

  const createCoupon = () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Create Coupon',
    });

    setIsShowModal(true);
  };

  return (
    <Layout
      title={`${corporateAccountName} ${t('corporate.title')}`}
      isLoading={!coupons && isLoading}
    >
      <CorporateNav
        actions={
          <Button
            label={t('create-coupon-modal.create-a-coupon-btn')}
            size="small"
            onClick={() => {
              setIsShowModal(true);
            }}
          />
        }
      />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.coupon}>
              {t('coupon-listing.table-header.coupon')}
            </th>
            <th>{t('coupon-listing.table-header.condition')}</th>
            <th className={styles.redemptions}>
              {t('coupon-listing.table-header.number-of-redemptions')}
            </th>
            <th>{t('coupon-listing.table-header.date-of-expiry')}</th>
          </tr>
        </thead>
        <tbody>
          {coupons &&
            coupons.length > 0 &&
            coupons.map((coupon) => (
              <CouponRow key={coupon.id} coupon={coupon} />
            ))}
        </tbody>
      </table>
      {!coupons?.length ? (
        <EmptyStateView
          src={IconCouponsActive}
          title={isTestDataMode ? t('No test coupon') : t('No coupon')}
        >
          <>
            <span>
              <Trans
                i18nKey={
                  isTestDataMode
                    ? 'coupon-listing.empty-state.test.desc'
                    : 'coupon-listing.empty-state.desc'
                }
              />
            </span>
            <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
            <Button
              className={styles['create-coupon']}
              size="small"
              label={
                isTestDataMode
                  ? t('coupon-listing.create-a-test-coupon')
                  : t('coupon-listing.create-a-coupon')
              }
              onClick={createCoupon}
            />
          </>
        </EmptyStateView>
      ) : (
        <PaginationView
          hasPrevPage={offset > 0}
          hasNextPage={hasNextPage}
          screen={screen}
          onPrevClick={() =>
            setQuery(
              {
                offset: offset - DEFAULT_PAGE_SIZE,
              },
              'replaceIn'
            )
          }
          onNextClick={() =>
            setQuery(
              {
                offset: data?.nextOffset,
              },
              'replaceIn'
            )
          }
        />
      )}

      {isShowModal && (
        <CreateCouponModal
          type="corporate"
          hide={() => {
            refetch();
            setIsShowModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default CorporateCouponListScreen;
