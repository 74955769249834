import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Mixpanel from '@smartpay/mixpanel';
import Button from '../../components/Form/Button';
import { CANCEL_ORDER_MODAL_SCREEN } from '../../constants';
import { Order } from '../../types/order';
import { emptyFn } from '../../utils/helper';
import { useCancelMutation } from '../../services/order';
import useViewMode from '../../hooks/use-view-mode';

import styles from './Modal.module.scss';

const screen = CANCEL_ORDER_MODAL_SCREEN;

const CancelOrderModal = ({
  order,
  onCancelSuccessful = emptyFn,
  onDismiss,
}: {
  order: Order;
  onCancelSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('translation');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { merchantId } = useViewMode();
  const [cancelOrder] = useCancelMutation();

  return (
    <div className={styles['modal-background']}>
      <div className={styles.modal}>
        <div>
          <h3 id="cancel_order_modal_title">{t('cancel-order-modal.title')}</h3>
          <p>{t('cancel-order-modal.desc')}</p>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="cancel_order_cancel"
            label={t('cancel-order-modal.cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'No',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_cancel_order_confirm"
            label={t('cancel-order-modal.confirm-btn')}
            size="small"
            onClick={async () => {
              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Yes',
              });

              try {
                const result = await cancelOrder({
                  id: order.id,
                  merchant: merchantId,
                });

                if ('error' in result) {
                  throw new Error(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (result.error as any).data || 'unexpected_error'
                  );
                }

                if (result.data && 'errorCode' in result.data) {
                  throw new Error(result.data.errorCode);
                }

                onCancelSuccessful();
                onDismiss();
              } catch (error) {
                setErrorMessage(t('error.unspecific'));
              }

              setIsLoading(false);
            }}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelOrderModal;
