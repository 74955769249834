import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams } from 'use-query-params';
import IconPaymentLinkActive from '../../assets/icon-sidebar-payment-link-active.svg';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Button from '../../components/Form/Button';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import PaginationView from '../../components/PaginationView/PaginationView';
import {
  PAYMENT_LINKS_SCREEN,
  SMARTPAY_DOCUMENT_URL,
  DEFAULT_PAGE_SIZE_PAYMENT_LINK,
} from '../../constants';
import { usePaymentLinksQuery } from '../../services/payment-link';
import { useDataMode } from '../../utils/helper';
import styles from './List.module.scss';
import CreatePaymentLinkModal from './ModalCreate';
import Row from './Row';
import useViewMode, { hasPermission } from '../../hooks/use-view-mode';

const screen = PAYMENT_LINKS_SCREEN;
const pageSize = DEFAULT_PAGE_SIZE_PAYMENT_LINK;

const List: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const [isShowModal, setIsShowModal] = useState(false);

  const { t } = useTranslation();
  const { merchantId, roles } = useViewMode();
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;
  const { test: isTest } = useDataMode();
  const { data, isLoading, refetch } = usePaymentLinksQuery({
    merchant: merchantId,
    offset,
    test: isTest,
    limit: pageSize,
  });
  const paymentLinks = data?.data;
  const hasNextPage = !!data?.nextOffset;

  const onCreatePaymentLink = () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Create Payment Link',
    });

    setIsShowModal(true);
  };

  return (
    <Layout>
      <div className={styles['top-bar']}>
        <h1>{t('payment-link.main-title')}</h1>
        {hasPermission(roles, 'PayLinksEditor') && (
          <Button
            id="btn_create_payment_link_cta"
            className={styles.cta}
            label={
              isTest
                ? t('payment-link.action.create-test-payment-link')
                : t('payment-link.action.create-payment-link')
            }
            size="small"
            onClick={onCreatePaymentLink}
          />
        )}
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className="align-center" style={{ width: 40 }}>
              {t('payment-link.th.link')}
            </th>
            <th style={{ width: '80%' }}>{t('payment-link.th.items')}</th>
            <th className="align-right">{t('payment-link.th.price')}</th>
            <th>{t('payment-link.th.created-at')}</th>
            <th className="align-center">{t('payment-link.th.status')}</th>
          </tr>
        </thead>
        <tbody>
          {paymentLinks &&
            paymentLinks.length > 0 &&
            paymentLinks.map((link) => {
              return <Row key={link.id} link={link} refetchList={refetch} />;
            })}
        </tbody>
      </table>

      {isLoading && <Loading width={48} top={48} />}

      {!isLoading && paymentLinks?.length === 0 ? (
        <EmptyStateView
          src={IconPaymentLinkActive}
          title={
            isTest
              ? 'No test payment links available.'
              : 'No payment links available.'
          }
        >
          <>
            <span>
              {isTest
                ? t('payment-link.action.create-test-payment-link')
                : t('payment-link.action.create-payment-link')}
            </span>
            <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
            {hasPermission(roles, 'PayLinksEditor') && (
              <Button
                className={styles['btn-empty-create-payment-link']}
                size="small"
                label={
                  isTest
                    ? t('payment-link.action.create-test-payment-link')
                    : t('payment-link.action.create-payment-link')
                }
                onClick={onCreatePaymentLink}
              />
            )}
          </>
        </EmptyStateView>
      ) : (
        <PaginationView
          hasPrevPage={offset > 0}
          hasNextPage={hasNextPage}
          screen={screen}
          onPrevClick={() =>
            setQuery(
              {
                offset: offset - pageSize,
              },
              'replaceIn'
            )
          }
          onNextClick={() =>
            setQuery(
              {
                offset: data?.nextOffset,
              },
              'replaceIn'
            )
          }
        />
      )}
      {isShowModal && (
        <CreatePaymentLinkModal
          onSuccess={refetch}
          onDismiss={() => setIsShowModal(false)}
        />
      )}
    </Layout>
  );
};

export default List;
