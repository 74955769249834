import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useViewMode from '../../hooks/use-view-mode';

const SettingsScreen: FC<RouteComponentProps> = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { base } = useViewMode();

  useEffect(() => {
    navigate(`${base}/settings/accounts`, { replace: true });
  }, [base, dispatch, search]);

  return null;
};

export default SettingsScreen;
