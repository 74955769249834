import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import cx from 'classnames';

import styles from './Toggle.module.scss';

const emptyFn = () => {};

const Toggle = ({
  name,
  leftText = '',
  rightText = '',
  checked = false,
  disabled = false,
  controlled = false,
  offColor = '#dfe1e5',
  onColor = '#7280ff',
  onChange = emptyFn,
  containerClassName,
}: {
  name?: string;
  leftText?: string;
  rightText?: string;
  checked?: boolean;
  disabled?: boolean;
  controlled?: boolean;
  offColor?: string;
  onColor?: string;
  onChange?: (event: boolean) => void;
  containerClassName?: string;
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (controlled) {
      setIsChecked(checked);
    }
  }, [checked, controlled]);

  return (
    <div className={cx(styles['toggle-container'], containerClassName)}>
      <div className={styles['inner-toggle']}>
        <span className={isChecked || disabled ? styles.inactive : ''}>
          {leftText}
        </span>
        <Switch
          name={name}
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event);
            if (onChange) {
              onChange(event);
            }
          }}
          offColor={offColor}
          onColor={onColor}
          checkedIcon={false}
          uncheckedIcon={false}
          height={21}
          width={38}
          disabled={disabled}
          handleDiameter={15}
          activeBoxShadow=""
        />
        <span className={!isChecked || disabled ? styles.inactive : ''}>
          {rightText}
        </span>
      </div>
    </div>
  );
};

export default Toggle;
