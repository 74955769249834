import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';

import useAppDispatch from '../../hooks/use-app-dispatch';
import { signOut } from '../../redux/auth';
import { orderAPI } from '../../services/order';
import { useAccountInfoQuery } from '../../services/setting';

import srcPopFrom from '../../assets/menu-pop-from.svg';

import styles from './ProfilePopup.module.scss';

export const PROFILE_POPUP_SCREEN = 'Profile Popup';

const ProfilePopup = ({ className }: { className?: string }) => {
  useTrackPageView({ screen: PROFILE_POPUP_SCREEN });

  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');
  const { data: accountInfo } = useAccountInfoQuery();
  const firstRef = useRef<HTMLAnchorElement>(null);

  const profileMenu = (
    <div className={styles.content}>
      <span>{accountInfo?.username || ''}</span>
      <span
        id="logout"
        onClick={() => {
          Mixpanel.trackAction({
            screen: PROFILE_POPUP_SCREEN,
            action: 'Click',
            itemName: 'Logout',
          });
          dispatch(signOut());
          dispatch(orderAPI.util.resetApiState());
        }}
      >
        {t('profile-popup.menu-item.logout')}
      </span>
    </div>
  );

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current.focus();
    }
  }, []);

  return (
    <div className={cx(styles['profile-popup-container'], className)}>
      {profileMenu}
      <img
        src={srcPopFrom}
        alt=""
        width={16}
        height={26}
        className={styles['pop-from']}
      />
    </div>
  );
};

export default ProfilePopup;
