import cx from 'classnames';
import { PayoutStatus } from '../../redux/payout';
import { OrderDisplayStatus } from '../../types/order';
import styles from './Capsule.module.scss';
import { OrderEventType } from '../../screens/OrderDetailScreen/utils';
import { BranchStatus } from '../../types/setting';

export type CapsuleVariant =
  | 'default'
  | 'payment'
  | 'order'
  | 'mode'
  | 'sponsored'
  | 'invalid';
export type CapsuleColor =
  | 'gray'
  | 'dark-gray'
  | 'green'
  | 'tag-green'
  | 'cerise'
  | 'yellow'
  | 'orange'
  | 'blue';

type AllStatus =
  | BranchStatus
  | OrderDisplayStatus
  | PayoutStatus
  | OrderEventType
  | 'onhold';

export interface CapsuleProps {
  id?: string;
  title: string;
  variant?: CapsuleVariant;
  status?: AllStatus | undefined;
  color?: CapsuleColor;
  size?: 'large' | 'small';
  className?: string;
}

const getColor = (status: AllStatus): CapsuleColor => {
  switch (status) {
    case 'succeeded':
    case 'capture':
    case 'paid':
      return 'green';
    case 'partially_captured':
      return 'tag-green';
    case 'active':
    case 'requires_capture':
    case 'onhold':
      return 'blue';
    case 'canceled':
      return 'dark-gray';
    case 'inactive':
      return 'orange';
    default:
      return 'gray';
  }
};

const Capsule = ({
  id = '',
  title,
  variant = 'default',
  status = undefined,
  color = 'gray',
  size = 'large',
  className = '',
}: CapsuleProps) => {
  return (
    <i
      id={id}
      className={cx(
        styles.capsule,
        styles[variant],
        styles[color],
        styles[size],
        status && styles[getColor(status)],
        className
      )}
    >
      {title}
    </i>
  );
};

export default Capsule;
