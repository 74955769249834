import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { settingAPI } from '../services/setting';

export type AccountStatus = 'test' | 'active';
export type DataMode = 'live' | 'test';

const initialState: {
  dataMode: DataMode;
  dataModeInited: boolean;
} = {
  dataMode: 'test',
  dataModeInited: false,
};

const settingSlice = createSlice({
  name: '@@merchant-dashboard/setting-accounts',
  initialState,
  reducers: {
    setDataMode(state, action: PayloadAction<DataMode>) {
      return {
        ...state,
        dataMode: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      settingAPI.endpoints.accountInfo.matchFulfilled,
      (state, action) => {
        if (state.dataModeInited) {
          return {
            ...state,
            dataMode:
              action.payload.corporateAccountStatus === 'test'
                ? 'test'
                : state.dataMode,
          };
        }

        return {
          ...state,
          dataModeInited: true,
          dataMode:
            action.payload.corporateAccountStatus === 'test' ? 'test' : 'live',
        };
      }
    );
  },
});

const persistConfig = {
  version: 1,
  key: 'settingAccounts',
  storage,
  blacklist: [],
};

export const { setDataMode } = settingSlice.actions;
export default persistReducer(persistConfig, settingSlice.reducer);
