import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import {
  useAccountInfoQuery,
  useCorporateLegalEntityQuery,
} from '../../services/setting';
import CorporateNav from '../../components/CorporateNav/CorporateNav';

import styles from './AccountsScreen.module.scss';

const CorporateAccountsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');
  const { data: accountInfo } = useAccountInfoQuery();
  const { data: legalEntity } = useCorporateLegalEntityQuery();

  return (
    <Layout
      title={`${accountInfo?.corporateAccountName} ${t('corporate.title')}`}
    >
      <CorporateNav />

      <div className={styles.container}>
        <section>
          <h2>{t('settings.accounts.contact-info.title')}</h2>
          <TextInput
            label={t('settings.accounts.contact-info.name')}
            variant="secondary"
            name="name"
            value={legalEntity?.businessInfo.name}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.phone-number')}
            variant="secondary"
            name="phone_number"
            value={legalEntity?.businessInfo.phoneNumber ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.last-name')}
            variant="secondary"
            name="last_name"
            value={legalEntity?.representative?.lastName ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.contact-info.first-name')}
            variant="secondary"
            name="first_name"
            value={legalEntity?.representative?.firstName ?? ''}
            className={styles['col-2']}
            readOnly
          />
          {accountInfo?.country === 'jp' && (
            <>
              <TextInput
                label={t('settings.accounts.contact-info.last-name-kata')}
                variant="secondary"
                name="last_name_kata"
                value={legalEntity?.representative?.lastNameKana ?? ''}
                className={styles['col-2']}
                readOnly
              />
              <TextInput
                label={t('settings.accounts.contact-info.first-name-kata')}
                variant="secondary"
                name="fist_name_kata"
                value={legalEntity?.representative?.firstNameKana ?? ''}
                className={styles['col-2']}
                readOnly
              />
            </>
          )}
        </section>
        <section>
          <h2>{t('settings.accounts.legal-info.title')}</h2>
          <TextInput
            label={t('settings.accounts.legal-info.corporate-number')}
            variant="secondary"
            name="corporate_number"
            value={legalEntity?.businessInfo.corporateNumber?.toString() ?? ''}
            readOnly
          />

          <TextInput
            label={t('settings.accounts.legal-info.company-name')}
            variant="secondary"
            name="company_name"
            value={legalEntity?.businessInfo.name ?? ''}
            readOnly
          />
          {accountInfo?.country === 'jp' && (
            <TextInput
              label={t('settings.accounts.legal-info.company-name-kata')}
              variant="secondary"
              name="company_name_kana"
              value={legalEntity?.businessInfo.nameKana ?? ''}
              readOnly
            />
          )}
          <TextInput
            label={t('settings.accounts.legal-info.postal-code')}
            variant="secondary"
            name="postal_code"
            leftIcon="zip"
            value={legalEntity?.officeInfo.postalCode ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.prefecture')}
            variant="secondary"
            name="prefecture"
            value={legalEntity?.officeInfo.state ?? ''}
            className={styles['col-2']}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.city-ward-locality')}
            variant="secondary"
            name="locality"
            value={legalEntity?.officeInfo.city ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.number')}
            variant="secondary"
            name="number"
            value={legalEntity?.officeInfo.addressLine1 ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.building-name-room-number')}
            variant="secondary"
            name="building_name"
            value={legalEntity?.officeInfo.addressLine2 ?? ''}
            readOnly
          />
          <TextInput
            label={t('settings.accounts.legal-info.website')}
            variant="secondary"
            name="website"
            value={legalEntity?.businessInfo.website ?? ''}
            readOnly
          />
        </section>
      </div>
      <p className={styles.contact}>
        <Trans
          i18nKey="settings.accounts.contact-support-desc"
          components={{
            supportLink: (
              <a
                href="mailto:support@smartpay.co"
                onClick={() =>
                  Mixpanel.trackAction({
                    action: 'Click',
                    itemName: 'Contact our support',
                  })
                }
              >
                Contact our support
              </a>
            ),
          }}
        />
      </p>
    </Layout>
  );
};

export default CorporateAccountsScreen;
