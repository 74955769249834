import { DEFAULT_PAGE_SIZE } from '../constants';
import {
  Coupon,
  CreateCouponParams,
  CreatePromotionCodeParams,
  PromotionCode,
  UpdateCouponParams,
  UpdatePromotionCodeParams,
} from '../types/coupon';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

export interface GetCouponsParams extends TestParams, PageParams {
  merchant?: string;
}

export interface GetCouponParams {
  id: string;
  merchant?: string;
}

export interface GetPromotionCodesParams extends TestParams, PageParams {
  couponId: string;
  merchant?: string;
}

export interface GetPromotionCodeParams {
  id: string;
  merchant?: string;
}

export const couponAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    coupons: builder.query<ListResponse<Coupon>, GetCouponsParams>({
      query: (params) => {
        return {
          url: `/me/merchant/coupons`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    corporateCoupons: builder.query<ListResponse<Coupon>, GetCouponsParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/coupons`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    coupon: builder.query<Coupon, GetCouponParams>({
      query: ({ id, merchant }) => ({
        url: `/me/merchant/coupons/${id}`,
        params: { merchant },
      }),
    }),
    corporateCoupon: builder.query<Coupon, GetCouponParams>({
      query: ({ id, merchant }) => ({
        url: `/me/corporate-account/coupons/${id}`,
        params: { merchant },
      }),
    }),
    createCoupon: builder.mutation<Coupon, CreateCouponParams>({
      query: ({ merchant, input }) => ({
        url: `/me/merchant/coupons/`,
        params: { merchant },
        data: input,
        method: 'POST',
      }),
    }),
    createCorporateCoupon: builder.mutation<Coupon, CreateCouponParams>({
      query: ({ input }) => ({
        url: `/me/corporate-account/coupons/`,
        data: input,
        method: 'POST',
      }),
    }),
    updateCoupon: builder.mutation<Coupon, UpdateCouponParams>({
      query: ({ id, merchant, input }) => ({
        url: `/me/merchant/coupons/${id}`,
        params: { merchant },
        data: input,
        method: 'PATCH',
      }),
    }),
    updateCorporateCoupon: builder.mutation<Coupon, UpdateCouponParams>({
      query: ({ id, input }) => ({
        url: `/me/corporate-account/coupons/${id}`,
        data: input,
        method: 'PATCH',
      }),
    }),
    promotionCodes: builder.query<
      ListResponse<PromotionCode>,
      GetPromotionCodesParams
    >({
      query: (params) => {
        const { couponId, ...restParams } = params;

        return {
          url: `/me/promotion-codes`,
          params: {
            ...restParams,
            // Keep using couponId for better readability
            coupon: couponId,
          },
        };
      },
    }),
    corporatePromotionCodes: builder.query<
      ListResponse<PromotionCode>,
      GetPromotionCodesParams
    >({
      query: (params) => {
        const { couponId, ...restParams } = params;

        return {
          url: `/me/corporate-account/promotion-codes`,
          params: {
            ...restParams,
            // Keep using couponId for better readability
            coupon: couponId,
          },
        };
      },
    }),
    promotionCode: builder.query<PromotionCode, GetPromotionCodeParams>({
      query: ({ id, merchant }) => {
        return {
          url: `/me/promotion-codes/${id}`,
          params: { merchant },
        };
      },
    }),
    corporatePromotionCode: builder.query<
      PromotionCode,
      GetPromotionCodeParams
    >({
      query: ({ id }) => {
        return {
          url: `/me/corporate-account/promotion-codes/${id}`,
        };
      },
    }),
    createPromotionCode: builder.mutation<
      PromotionCode,
      CreatePromotionCodeParams
    >({
      query: ({ merchant, input }) => ({
        url: `/me/merchant/promotion-codes/`,
        params: { merchant },
        data: input,
        method: 'POST',
      }),
    }),
    createCorporatePromotionCode: builder.mutation<
      PromotionCode,
      CreatePromotionCodeParams
    >({
      query: ({ input }) => ({
        url: `/me/corporate-account/promotion-codes/`,
        data: input,
        method: 'POST',
      }),
    }),
    updatePromotionCode: builder.mutation<
      PromotionCode,
      UpdatePromotionCodeParams
    >({
      query: ({ id, merchant, input }) => ({
        url: `/me/merchant/promotion-codes/${id}`,
        params: { merchant },
        data: input,
        method: 'PATCH',
      }),
    }),
    updateCorporatePromotionCode: builder.mutation<
      PromotionCode,
      UpdatePromotionCodeParams
    >({
      query: ({ id, input }) => ({
        url: `/me/corporate-account/promotion-codes/${id}`,
        data: input,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCouponsQuery,
  useCorporateCouponsQuery,
  useCouponQuery,
  useCorporateCouponQuery,
  usePromotionCodesQuery,
  usePromotionCodeQuery,
  useCorporatePromotionCodesQuery,
  useCorporatePromotionCodeQuery,
  useCreateCouponMutation,
  useCreateCorporateCouponMutation,
  useUpdateCouponMutation,
  useUpdateCorporateCouponMutation,
  useCreatePromotionCodeMutation,
  useCreateCorporatePromotionCodeMutation,
  useUpdatePromotionCodeMutation,
  useUpdateCorporatePromotionCodeMutation,
} = couponAPI;
