import { RouteComponentProps, navigate } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import Time from '../../components/Time/Time';
import CorporateNav from '../../components/CorporateNav/CorporateNav';
import {
  useCorporateBankAccountQuery,
  useCorporatePayoutScheduleQuery,
  useCorporatePlanFeesQuery,
} from '../../services/setting';
import useViewMode from '../../hooks/use-view-mode';
import PlanFees from '../../components/PlanFees/PlanFees';

import styles from './PaymentsScreen.module.scss';

const CorporatePaymentsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');

  const { corporateAccountName } = useViewMode();
  const { data: bankAccount } = useCorporateBankAccountQuery();
  const { data: payoutSchedule } = useCorporatePayoutScheduleQuery();
  const { data: planFeesData } = useCorporatePlanFeesQuery();

  const planFees = planFeesData?.data;

  return (
    <Layout title={`${corporateAccountName} ${t('corporate.title')}`}>
      <CorporateNav />
      <div className={styles.container}>
        {bankAccount && (
          <section>
            <h2>{t('settings.payments.bank-account.title')}</h2>
            {bankAccount.country && bankAccount.country !== 'jp' && (
              <TextInput
                label={t('settings.payments.bank-account.country')}
                name="country"
                variant="secondary"
                value={t(`country.${bankAccount.country}`)}
                readOnly
              />
            )}
            <TextInput
              label={t('settings.payments.bank-account.bank-account-name')}
              name="name"
              variant="secondary"
              value={bankAccount.name}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.bank-code')}
              name="bank code"
              variant="secondary"
              value={bankAccount.bankCode}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.branch-number')}
              name="branch_number"
              variant="secondary"
              value={bankAccount.branchCode}
              readOnly
            />
            <TextInput
              label={t('settings.payments.bank-account.account-number')}
              name="account_number"
              variant="secondary"
              value={bankAccount.accountNumber.replaceAll('X', '•')}
              readOnly
            />
            {bankAccount.accountType && (
              <TextInput
                label={t('settings.payments.bank-account.account-type')}
                name="account_type"
                variant="secondary"
                value={bankAccount.accountType}
                readOnly
              />
            )}
            <TextInput
              label={t('settings.payments.bank-account.currency')}
              name="currency"
              variant="secondary"
              value={bankAccount.currency}
              readOnly
            />
          </section>
        )}
        <div className={styles.col}>
          <section className={styles.fees}>
            <h2>{t('settings.payments.fee.title')}</h2>
            <PlanFees
              data={planFees}
              onClick={() => {
                navigate('/c/payment-options');
              }}
            />
          </section>
          {payoutSchedule?.schedule && (
            <section className={styles['payout-schedule']}>
              <h2>{t('settings.payments.payout-schedule.title')}</h2>

              <label>
                {t('settings.payments.payout-schedule.payout-schedule')}
              </label>
              <p>{t(payoutSchedule.schedule)}</p>
              <label>
                {t('settings.payments.payout-schedule.next-payment')}
              </label>
              <div>
                <p className={styles.period}>
                  <Time
                    dateTime={payoutSchedule.period.start}
                    formatStr={t('format.MMMdd', 'MM/dd')}
                  />
                  {t('format.periodTo')}
                  <Time
                    dateTime={payoutSchedule.period.end}
                    formatStr={t('format.MMMdd', 'MM/dd')}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="settings.payments.payout-schedule.on-date"
                    components={[
                      <Time
                        dateTime={payoutSchedule.period.nextPaymentAt}
                        formatStr={t('format.yyyyMMMdd', 'yyyy/MM/dd')}
                      />,
                    ]}
                  />
                </p>
              </div>
            </section>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CorporatePaymentsScreen;
