import { APIError } from '../types';
import {
  CancelMutation,
  CaptureMutation,
  Order,
  Payment,
  PaymentTransaction,
  RefundMutation,
} from '../types/order';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
};

export interface GetOrdersParams extends TestParams, PageParams {
  merchant?: string;
  search?: string;
  statuses?: string;
  promotionCode?: string;
}

export interface GetOrderParams {
  id: string;
  merchant?: string;
}

export const orderAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    orders: builder.query<ListResponse<Order>, GetOrdersParams>({
      query: (orderParams) => {
        return {
          url: `/me/merchant/orders`,
          params: {
            ...defaultParams,
            ...orderParams,
          },
        };
      },
    }),
    corporateOrders: builder.query<ListResponse<Order>, GetOrdersParams>({
      query: (orderParams) => {
        return {
          url: `/me/corporate-account/orders`,
          params: {
            ...defaultParams,
            ...orderParams,
          },
        };
      },
    }),
    order: builder.query<Order, GetOrderParams>({
      query: ({ id, merchant }) => ({
        url: `/me/merchant/orders/${id}`,
        params: { merchant },
      }),
    }),
    corporateOrder: builder.query<Order, string>({
      query: (orderId) => ({
        url: `/me/corporate-account/orders/${orderId}`,
      }),
    }),
    transactions: builder.query<PaymentTransaction[], GetOrderParams>({
      query: ({ id, merchant }) => ({
        url: `/me/transactions?order=${id}`,
        params: { merchant },
      }),
    }),
    cancel: builder.mutation<void | APIError, CancelMutation>({
      query: ({ id, merchant }) => ({
        url: `/me/merchant/orders/${id}/cancellation`,
        method: 'PUT',
        params: {
          merchant,
        },
      }),
    }),
    capture: builder.mutation<Payment | APIError, CaptureMutation>({
      query: ({ merchant, payload }) => ({
        url: `/me/merchant/payments`,
        method: 'POST',
        params: {
          merchant,
        },
        data: payload,
      }),
    }),
    refund: builder.mutation<void | APIError, RefundMutation>({
      query: ({ merchant, payload }) => ({
        url: `/me/merchant/refunds`,
        method: 'POST',
        params: {
          merchant,
        },
        data: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useOrdersQuery,
  useCorporateOrdersQuery,
  useOrderQuery,
  useCorporateOrderQuery,
  useTransactionsQuery,
  useCaptureMutation,
  useCancelMutation,
  useRefundMutation,
} = orderAPI;
