import Mixpanel from '@smartpay/mixpanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Form/Button';
import TextArea from '../../components/Form/TextArea';
import TextInput from '../../components/Form/TextInput';
import { REFUND_MODAL_SCREEN } from '../../constants';
import { ERROR_AMOUNT_INSUFFICIENT } from '../../redux/error-codes';
import { Order } from '../../types/order';
import createEventHandler from '../../utils/create-event-handler';
import { emptyFn, formatCurrency } from '../../utils/helper';
import useViewMode from '../../hooks/use-view-mode';
import { useRefundMutation } from '../../services/order';

import styles from './Modal.module.scss';

export const getFirstRefundablePayment = (order?: Order) => {
  let paymentId = '';
  let refundableAmount = 0;

  order?.payments?.forEach((payment) => {
    if (paymentId === '') {
      const paymentRefundableAmount =
        payment.refunds?.reduce((prev, refund) => {
          return prev - refund.amount;
        }, payment.amount) || 0;

      if (paymentRefundableAmount > 0) {
        paymentId = payment.id;
        refundableAmount = paymentRefundableAmount;
      }
    }
  });

  return {
    paymentId,
    refundableAmount,
  };
};

const RefundModal = ({
  order,
  onRefundSuccessful = emptyFn,
  onDismiss,
}: {
  order: Order;
  onRefundSuccessful?: () => void;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('translation');
  const { paymentId, refundableAmount } = getFirstRefundablePayment(order);
  const [refundAmount, setAmount] = useState(refundableAmount);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { merchantId } = useViewMode();
  const [refundOrder] = useRefundMutation();

  if (!order.payments) {
    return null;
  }

  const payment = order.payments[0];

  return (
    <div className={styles['modal-background']}>
      <div className={styles.modal}>
        <div>
          <h3 id="refund_modal_title">{t('refund-modal.title')}</h3>
          <p>{t('refund-modal.desc')}</p>
        </div>
        <div className={styles['modal-description']}>
          <TextInput
            name="refund-amount"
            type="number"
            rightText={payment.currency}
            label={t('refund-modal.refund-label')}
            value={refundAmount.toString()}
            onChange={(event) => {
              setAmount(Number(event.currentTarget.value));
            }}
            errorMessage={errorMessage}
            autoFocus
          />
          <TextArea
            name="refund-description"
            label={t('refund-modal.desc-label')}
            placeholder={t('refund-modal.desc-placeholder')}
            value={description}
            onChange={(event) => {
              setDescription(event.currentTarget.value);
            }}
          />
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="btn_refund_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen: REFUND_MODAL_SCREEN,
                action: 'Click',
                itemName: 'Refund Cancel',
              });

              onDismiss();
            }}
          />
          <Button
            id="btn_refund_confirm"
            label={t('refund-modal.confirm-btn')}
            size="small"
            onClick={createEventHandler(async () => {
              if (refundAmount > refundableAmount) {
                setErrorMessage(
                  t('refund-modal.error.amount-too-high', {
                    refundableAmount: formatCurrency(
                      refundableAmount,
                      payment.currency
                    ),
                  })
                );
                return;
              }

              if (isLoading) {
                return;
              }

              setIsLoading(true);

              Mixpanel.trackAction({
                screen: REFUND_MODAL_SCREEN,
                action: 'Click',
                itemName: 'Refund Confirm',
              });

              try {
                const result = await refundOrder({
                  id: order.id,
                  merchant: merchantId,
                  payload: {
                    amount: refundAmount,
                    currency: payment.currency,
                    lineItems: [],
                    payment: paymentId,
                    reason: 'requested_by_customer',
                    description,
                  },
                });

                if ('error' in result) {
                  throw new Error(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (result.error as any).data || 'unexpected_error'
                  );
                }

                if (result.data && 'errorCode' in result.data) {
                  throw new Error(result.data.errorCode);
                }

                onRefundSuccessful();
                onDismiss();
              } catch (error) {
                if (error instanceof Error) {
                  switch (error.message) {
                    case ERROR_AMOUNT_INSUFFICIENT:
                      setErrorMessage(
                        t('refund-modal.error.amount-too-high', {
                          refundableAmount: formatCurrency(
                            refundableAmount,
                            payment.currency
                          ),
                        })
                      );
                      break;
                    default:
                      setErrorMessage(t('error.unspecific'));
                  }
                }
              }

              setIsLoading(false);
            })}
            disabled={refundAmount <= 0}
            processing={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default RefundModal;
