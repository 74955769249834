import cx from 'classnames';
import { ReactNode, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Popup from '../Popup/Popup';
import IconCopyGray from '../../assets/icon-content-copy-gray.svg';
import IconCopy from '../../assets/icon-content-copy.svg';
import IconCheck from '../../assets/icon-checked-20.svg';
import styles from './CopyableText.module.scss';

export type IconColor = 'default' | 'gray';

const getIconSrc = (color: IconColor = 'default') => {
  return color === 'gray' ? IconCopyGray : IconCopy;
};

const CopyableText = ({
  onClick,
  children,
  value,
  id,
  direction = 'up',
  className,
}: {
  onClick?: () => void;
  children?: ReactNode;
  value?: string;
  id?: string;
  direction?: 'up' | 'down';
  className?: string;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const icon = (
    <img
      className={cx(styles.icon)}
      src={getIconSrc('gray')}
      width={16}
      height={16}
      alt=""
      loading="lazy"
    />
  );

  return (
    <span id={id} className={cx(styles.wrapper, className)}>
      {children}
      <Popup
        direction={direction}
        content={
          <div className={styles['popup-content']}>
            {isCopied ? (
              <>
                <img
                  src={IconCheck}
                  width={20}
                  height={20}
                  alt=""
                  loading="eager"
                />
                Copied
              </>
            ) : (
              'Copy'
            )}
          </div>
        }
        trigger={
          <div onMouseLeave={() => setIsCopied(false)}>
            <CopyToClipboard
              text={value || (children as string)}
              onCopy={() => {
                onClick?.();
                setIsCopied(true);
              }}
            >
              {icon}
            </CopyToClipboard>
          </div>
        }
      />
    </span>
  );
};

export default CopyableText;
