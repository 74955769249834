import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import payoutAPI, { PayoutParams } from '../api/payout';
import { ERROR_UNEXPECTED_ERROR } from './error-codes';

export type PayoutStatus = 'open' | 'paid' | 'closed';

export type Payout = {
  id: string;
  period: {
    start: string;
    end: string;
  };
  status: PayoutStatus;
  amount?: number;
  currency?: string;
  merchant?: string;
  merchantName?: string;
};

const initialState: {
  payouts: Payout[];
} = {
  payouts: [],
};

export const getPayouts = createAsyncThunk(
  'getPayouts',
  async (params: PayoutParams, { rejectWithValue }) => {
    try {
      const response = await payoutAPI.getPayouts(params);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({ errorCode: ERROR_UNEXPECTED_ERROR });
    }
  }
);

const settingSlice = createSlice({
  name: '@@merchant-dashboard/payouts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPayouts.fulfilled, (state, action) => {
      const payouts: Payout[] = action.payload.data;

      state.payouts = payouts || initialState.payouts;
    });
  },
});

export default settingSlice.reducer;
