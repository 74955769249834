import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { isValid as isValidDate } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Button from '../../components/Form/Button';
import Checkbox from '../../components/Form/Checkbox';
import LabeledComponent from '../../components/Form/LabeledComponent';
import RadioButton from '../../components/Form/RadioButton';
import TextInput from '../../components/Form/TextInput';
import { COUPON_CREATION_SCREEN } from '../../constants';
import { useDataMode } from '../../utils/helper';
import {
  useCreateCorporateCouponMutation,
  useCreateCouponMutation,
} from '../../services/coupon';

import styles from './CreateCouponModal.module.scss';
import { CreateCouponInput } from '../../types/coupon';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4456dd',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #dfe1e5', // default
            },
            '&:hover fieldset': {
              border: '1px solid #dfe1e5', // hover
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4456dd', // focus
            },
          },
        },
      },
    },
  },
});

const schema = z
  .object({
    name: z.string(),
    discountType: z.enum(['amount', 'percentage']),
    discountAmount: z.coerce.number().optional(),
    discountMax: z.coerce.number().optional(),
    discountPercentage: z.coerce.number().optional(),
    hasExpiryDate: z.boolean(),
    expiresAt: z.coerce.number().optional(),
    hasMaxRedemptions: z.boolean(),
    maxRedemptionCount: z.coerce.number().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.discountType === 'amount') {
      if (data.discountAmount !== undefined && data.discountAmount < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['discountAmount'],
          message: `invalid`,
        });
      }
    }

    if (data.discountType === 'percentage') {
      if (
        data.discountPercentage !== undefined &&
        (data.discountPercentage <= 0 || data.discountPercentage > 100)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['discountPercentage'],
          message: `invalid`,
        });
      }

      if (data.discountMax !== undefined && data.discountMax < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['discountMax'],
          message: `invalid`,
        });
      }
    }

    if (data.hasExpiryDate) {
      if (!isValidDate(data.expiresAt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['expiresAt'],
          message: `invalid`,
        });
      }
    }

    if (data.hasMaxRedemptions) {
      if (!data.maxRedemptionCount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['maxRedemptionCount'],
          message: `required`,
        });
      }
    }
  });

const CreateCouponModal = ({
  type,
  merchantId,
  hide,
}: {
  type: 'corporate' | 'merchant';
  merchantId?: string;
  hide: () => void;
}) => {
  const { t, i18n } = useTranslation('translation');
  const { test: isTestMode } = useDataMode();

  const [createMerchantCoupon, { isLoading: isCreatingMerchantCoupon }] =
    useCreateCouponMutation();
  const [createCoporateCoupon, { isLoading: isCreatingCorporateCoupon }] =
    useCreateCorporateCouponMutation();

  const createCoupon =
    type === 'merchant' ? createMerchantCoupon : createCoporateCoupon;
  const isLoading = isCreatingMerchantCoupon || isCreatingCorporateCoupon;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { isValid, errors },
  } = useForm<CreateCouponInput>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      discountType: 'percentage',
      discountAmount: undefined,
      discountMax: undefined,
      discountPercentage: undefined,
      hasExpiryDate: false,
      expiresAt: undefined,
      hasMaxRedemptions: false,
      maxRedemptionCount: undefined,
    },
  });

  const watchDiscountType = watch('discountType');
  const watchHasMaxRedemptions = watch('hasMaxRedemptions');
  const watchHasExpiryDate = watch('hasExpiryDate');
  const watchExpiresAt = watch('expiresAt');

  const onSubmit = useCallback(
    async (data: CreateCouponInput) => {
      try {
        const result = await createCoupon({
          merchant: merchantId,
          input: {
            name: data.name,
            discountType: data.discountType,
            currency: 'JPY',
            discountAmount:
              data.discountType === 'amount' ? data.discountAmount : undefined,
            discountMax:
              data.discountType === 'percentage' ? data.discountMax : undefined,
            discountPercentage:
              data.discountType === 'percentage'
                ? data.discountPercentage
                : undefined,
            expiresAt: data.hasExpiryDate ? data.expiresAt : undefined,
            maxRedemptionCount: data.hasMaxRedemptions
              ? data.maxRedemptionCount
              : undefined,
            test: isTestMode,
            active: true,
          },
        });

        if ('error' in result) {
          throw new Error(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (result.error as any).data.errorCode || 'unexpected_error'
          );
        }

        hide();
      } catch (error) {
        if (error instanceof Error) {
          setError('root', { type: 'custom', message: error.message });
        }
      }
    },
    [createCoupon, merchantId, isTestMode, hide, setError]
  );

  return (
    <div className={styles['modal-background']}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cx(styles.modal, isTestMode ? styles.test : null)}
      >
        <div>
          <h3 id="create-coupon-modal-title">
            {t('create-coupon-modal.title')}
          </h3>
          <p>{t('create-coupon-modal.desc')}</p>
        </div>
        <div className={styles.body}>
          <fieldset>
            <TextInput
              autoFocus
              label={t('create-coupon-modal.coupon-name.label')}
              placeholder={t('create-coupon-modal.coupon-name.placeholder')}
              {...register('name')}
              errorMessage={errors.name?.message}
            />
          </fieldset>
          <fieldset>
            <LabeledComponent
              label={t('create-coupon-modal.discount-type.title')}
              labelSpace="small"
            >
              <RadioButton
                id="discount-type-percent-discount"
                title={t('create-coupon-modal.discount-type.percent-discount')}
                checked={watchDiscountType === 'percentage'}
                onClick={() => {
                  setValue('discountType', 'percentage');
                }}
              />
              <RadioButton
                id="discount-type-amount-discount"
                title={t('create-coupon-modal.discount-type.amount-discount')}
                checked={watchDiscountType === 'amount'}
                onClick={() => {
                  setValue('discountType', 'amount');
                }}
              />
            </LabeledComponent>
          </fieldset>

          <fieldset>
            {watchDiscountType === 'percentage' && (
              <>
                <TextInput
                  label={t('create-coupon-modal.percent-discount.label')}
                  placeholder=""
                  type="number"
                  rightText="%"
                  max="100"
                  {...register('discountPercentage')}
                  errorMessage={errors.discountPercentage?.message}
                />
                <TextInput
                  label={t('create-coupon-modal.max-discount.label')}
                  placeholder=""
                  type="number"
                  rightText="￥"
                  {...register('discountMax')}
                  errorMessage={errors.discountMax?.message}
                />
              </>
            )}
            {watchDiscountType === 'amount' && (
              <TextInput
                label={t('create-coupon-modal.amount-discount.label')}
                placeholder=""
                type="number"
                rightText="￥"
                {...register('discountAmount')}
                errorMessage={errors.discountAmount?.message}
              />
            )}
          </fieldset>
          <fieldset>
            <Checkbox
              label={t('create-coupon-modal.max-redemption.title')}
              {...register('hasMaxRedemptions')}
              checked={watchHasMaxRedemptions}
            />
            {watchHasMaxRedemptions && (
              <TextInput
                autoFocus
                type="number"
                rightText="回"
                {...register('maxRedemptionCount')}
                errorMessage={errors.maxRedemptionCount?.message}
              />
            )}
          </fieldset>
          <fieldset>
            <Checkbox
              label={t('create-coupon-modal.expiry-date.title')}
              {...register('hasExpiryDate')}
              checked={watchHasExpiryDate}
            />
            {watchHasExpiryDate && (
              <div>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={i18n.language === 'ja' ? ja : enUS}
                  >
                    <DateTimePicker
                      value={watchExpiresAt || ''}
                      onChange={(value) =>
                        setValue('expiresAt', value?.getTime(), {
                          shouldValidate: true,
                        })
                      }
                      ampm={false}
                      minDateTime={new Date()}
                      inputFormat="yyyy/MM/dd HH:mm"
                      renderInput={(params) => (
                        <TextField autoFocus {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
            )}
          </fieldset>
        </div>
        <div className={styles['modal-footer']}>
          <Button
            id="btn_create_coupon_cancel"
            label={t('cancel-btn')}
            size="small"
            variant="outline"
            onClick={() => {
              Mixpanel.trackAction({
                screen: COUPON_CREATION_SCREEN,
                action: 'Click',
                itemName: 'Create Coupon Cancel',
              });

              hide();
            }}
          />
          <Button
            id="btn_create_coupon_confirm"
            label={t('create-coupon-modal.create-a-coupon-btn')}
            size="small"
            type="submit"
            disabled={!isValid}
            processing={isLoading}
          />
        </div>
        {!!errors.root && (
          <div className={styles['error-wrapper']}>
            <p>{t([`error.${errors.root}`, errors.root.message as string])}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateCouponModal;
