import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInterval from 'use-interval';
import { NumberParam, useQueryParams } from 'use-query-params';
import { Item, Menu } from 'react-contexify';

import IconOrdersActive from '../../assets/icon-sidebar-order-active.svg';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import { DEFAULT_PAGE_SIZE, USERS_SCREEN } from '../../constants';
import { useDataMode } from '../../utils/helper';
import UserRow from './UserRow';
import Loading from '../../components/Loading';
import useViewMode from '../../hooks/use-view-mode';
import { useUsersQuery } from '../../services/users';
import UserModal from './UserModal';
import DeleteUserModal from './DeleteUserModal';
import Button from '../../components/Form/Button';

import styles from './UserListScreen.module.scss';

const screen = USERS_SCREEN;

const UserListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');

  const [isShowCreateUserModal, setIsShowCreateUserModal] = useState(false);
  const [isShowEditUserModal, setIsShowEditUserModal] = useState(false);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState(false);

  const [activeUserId, setActiveUserId] = useState('');

  const { merchantId } = useViewMode();
  const { test: isTestDataMode } = useDataMode();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;

  const { data, isLoading, isFetching, refetch } = useUsersQuery(
    {
      merchant: merchantId,
      offset,
      test: isTestDataMode,
      limit: DEFAULT_PAGE_SIZE,
    },
    { refetchOnMountOrArgChange: 60 }
  );

  const users = data?.data;

  const onShowEditUserModal = useCallback((id: string) => {
    setActiveUserId(id);
    setIsShowEditUserModal(true);
  }, []);

  const onShowDeleteUserModal = useCallback((id: string) => {
    setActiveUserId(id);
    setIsShowDeleteUserModal(true);
  }, []);

  useInterval(
    () => {
      setQuery({
        offset,
      });
    },
    offset === 0 ? 30000 : null
  ); // refetch every 30 seconds, roughly

  return (
    <Layout isLoading={!users && isLoading}>
      <div className={styles['top-bar']}>
        <h1>{t('user-listing.title')}</h1>
        <Button
          label={t('user-modal.button.create-new-user')}
          size="small"
          onClick={() => {
            setActiveUserId('');
            setIsShowCreateUserModal(true);
          }}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('user-listing.table-header.user-email')}</th>
            <th>{t('user-listing.table-header.access')}</th>
            <th>{t('user-listing.table-header.created-at')}</th>
            <th className={styles.manager}> </th>
          </tr>
        </thead>
        <tbody>
          {users &&
            !isFetching &&
            users.length > 0 &&
            users.map((user) => <UserRow key={user.id} user={user} />)}
        </tbody>
      </table>

      <Menu id="user_context_menu">
        <Item onClick={({ props }) => onShowEditUserModal(props.id)}>
          {t('user-listing.menu.edit-user')}
        </Item>
        <Item onClick={({ props }) => onShowDeleteUserModal(props.id)}>
          {t('user-listing.menu.delete-user')}
        </Item>
      </Menu>

      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
      {!isFetching &&
        (users?.length === 0 ? (
          <EmptyStateView
            src={IconOrdersActive}
            title={t('user-listing.search-result.no-users')}
          />
        ) : (
          <PaginationView
            screen={screen}
            hasPrevPage={offset > 0}
            hasNextPage={!!data?.nextOffset}
            onPrevClick={() => {
              setQuery(
                {
                  offset: offset - DEFAULT_PAGE_SIZE,
                },
                'replaceIn'
              );
            }}
            onNextClick={() =>
              setQuery(
                {
                  offset: data?.nextOffset,
                },
                'replaceIn'
              )
            }
          />
        ))}

      {isShowCreateUserModal && (
        <UserModal
          type="merchant"
          hide={() => {
            refetch();
            setIsShowCreateUserModal(false);
          }}
        />
      )}
      {isShowEditUserModal && (
        <UserModal
          type="merchant"
          id={activeUserId}
          hide={() => {
            refetch();
            setIsShowEditUserModal(false);
          }}
        />
      )}
      {isShowDeleteUserModal && (
        <DeleteUserModal
          type="merchant"
          id={activeUserId}
          hide={() => {
            refetch();
            setIsShowDeleteUserModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default UserListScreen;
