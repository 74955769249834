import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Layout from '../../components/Layout';
import SettingNavBar from './SettingNavBar';
import Button from '../../components/Form/Button';
import Toggle from '../../components/Toggle/Toggle';
import {
  BatchPlanFee,
  EmptyPlanFee,
  PaymentMethodCategory,
  PaymentPlan,
  PlanFee,
} from '../../types/plan-fee';
import { useMerchantPlanFeesQuery } from '../../hooks/use-merchant-queries';
import { useForm } from 'react-hook-form';

import installments1Src from '../../assets/installments-1.svg';
import installments3Src from '../../assets/installments-3.svg';
import installments6Src from '../../assets/installments-6.svg';
import installments12Src from '../../assets/installments-12.svg';
import cardsSrc from '../../assets/pm/cards.svg';
import bankSrc from '../../assets/pm/bank.svg';

import styles from './PaymentOptionsScreen.module.scss';

interface PlanFeesInput {
  data: EmptyPlanFee[];
}

export const CATEGORY_SRC = {
  CardBased: cardsSrc,
  BankBased: bankSrc,
  '': '',
};

export const PLAN_SRC = {
  SmartpayOne: installments1Src,
  PayInThree: installments3Src,
  SmartpaySix: installments6Src,
  SmartpayTwelve: installments12Src,
  SixTwoTwo: '',
  '': '',
};

export const Per = <span className={styles.symbol}>%</span>;
export const Yen = <span className={styles.symbol}>¥</span>;
export const Add = <span className={styles.plus}>+</span>;
export const Na = <span className={styles.na}>Not Avaiable</span>;

export const findPlanFee = (
  data: EmptyPlanFee[] | undefined,
  paymentPlan: PaymentPlan,
  paymentMethodCategory: PaymentMethodCategory
) => {
  return data?.find(
    (option) =>
      option?.paymentPlan === paymentPlan &&
      option?.paymentMethodCategory === paymentMethodCategory
  );
};

export const findPaymentOption = (
  data: EmptyPlanFee[] | undefined,
  paymentPlan: PaymentPlan
) => {
  const card = findPlanFee(data, paymentPlan, 'CardBased') as PlanFee;
  const bank = findPlanFee(data, paymentPlan, 'BankBased') as PlanFee;

  return { card, bank };
};

const PlanFeeValue = ({ planFee }: { planFee?: BatchPlanFee }) => {
  return (
    <div className={styles.fee}>
      {planFee ? Math.floor(planFee.ratedFee * 10000) / 100 : '-'}
      {Per}
      {Add}
      {Yen}
      {planFee ? planFee.fixedFee : '-'}
    </div>
  );
};

const PaymentOptionsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const { t } = useTranslation('translation');
  const { data: planFeesData } = useMerchantPlanFeesQuery();

  const { handleSubmit, watch } = useForm<PlanFeesInput>({
    defaultValues: { data: [] },
  });
  const watchBatch = watch('data');
  const currentPlanFees = useMemo(() => {
    // place batch items in front of DB data so it will have higher priority
    const data = [...watchBatch, ...(planFeesData?.data || [])];

    return [
      findPaymentOption(data, 'SmartpayOne'),
      findPaymentOption(data, 'PayInThree'),
      findPaymentOption(data, 'SmartpaySix'),
      findPaymentOption(data, 'SmartpayTwelve'),
    ];
  }, [planFeesData, watchBatch]);
  const [SmartpayOne, PayInThree, SmartpaySix, SmartpayTwelve] =
    currentPlanFees;

  // const addToBatch = (task: EmptyPlanFee) => {
  //   if (!task) {
  //     return;
  //   }

  //   const batch = getValues('data');
  //   const existing = findPlanFee(
  //     batch,
  //     task.paymentPlan,
  //     task.paymentMethodCategory
  //   );

  //   if (existing) {
  //     // update by reference
  //     existing.status = task.status;
  //   } else {
  //     setValue('data', [...batch, task]);
  //   }
  // };

  // const onSubmit = async ({ data }: { data: EmptyPlanFee[] }) => {};
  const onSubmit = async () => {};

  return (
    <Layout title={t('settings.title')} className={styles.layout}>
      <SettingNavBar />
      <div className={styles.container}>
        <section>
          <div className={styles.main}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onClick={(e) => e.stopPropagation()}
              className={styles.box}
            >
              <h2>{t('settings.payment-options.title')}</h2>

              <div className={styles.options}>
                <div
                  className={cx(
                    styles.option,
                    !SmartpayOne.card && !SmartpayOne.bank && styles.disabled
                  )}
                >
                  <h3>{t('settings.payment-options.1-installment')}</h3>
                  <img src={installments1Src} alt="" width="56" height="56" />
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={cardsSrc} alt="Card" width="32" height="20" />
                    <Toggle
                      checked={SmartpayOne.card?.status === 'On'}
                      // disabled={
                      //   !SmartpayOne.card || SmartpayOne.card.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpayOne.card} />
                  </div>
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={bankSrc} alt="Bank" width="32" height="20" />
                    <Toggle
                      checked={SmartpayOne.bank?.status === 'On'}
                      // disabled={
                      //   !SmartpayOne.bank || SmartpayOne.bank.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpayOne.bank} />
                  </div>
                </div>
                <div
                  className={cx(
                    styles.option,
                    !PayInThree.card && !PayInThree.bank && styles.disabled
                  )}
                >
                  <h3>{t('settings.payment-options.3-installments')}</h3>
                  <img src={installments3Src} alt="" width="56" height="56" />
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={cardsSrc} alt="Card" width="32" height="20" />
                    <Toggle
                      checked={PayInThree.card?.status === 'On'}
                      // disabled={
                      //   !PayInThree.card || PayInThree.card.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={PayInThree.card} />
                  </div>
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={bankSrc} alt="Bank" width="32" height="20" />
                    <Toggle
                      checked={PayInThree.bank?.status === 'On'}
                      // disabled={
                      //   !PayInThree.bank || PayInThree.bank.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={PayInThree.bank} />
                  </div>
                </div>
                <div
                  className={cx(
                    styles.option,
                    !SmartpaySix.card && !SmartpaySix.bank && styles.disabled
                  )}
                >
                  <h3>{t('settings.payment-options.6-installments')}</h3>
                  <img src={installments6Src} alt="" width="56" height="56" />
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={cardsSrc} alt="Card" width="32" height="20" />
                    <Toggle
                      checked={SmartpaySix.card?.status === 'On'}
                      // disabled={
                      //   !SmartpaySix.card || SmartpaySix.card.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpaySix.card} />
                  </div>
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={bankSrc} alt="Bank" width="32" height="20" />
                    <Toggle
                      checked={SmartpaySix.bank?.status === 'On'}
                      // disabled={
                      //   !SmartpaySix.bank || SmartpaySix.bank.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpaySix.bank} />
                  </div>
                </div>
                <div
                  className={cx(
                    styles.option,
                    !SmartpayTwelve.card &&
                      !SmartpayTwelve.bank &&
                      styles.disabled
                  )}
                >
                  <h3>{t('settings.payment-options.12-installments')}</h3>
                  <img src={installments12Src} alt="" width="56" height="56" />
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={cardsSrc} alt="Card" width="32" height="20" />
                    <Toggle
                      checked={SmartpayTwelve.card?.status === 'On'}
                      // disabled={
                      //   !SmartpayTwelve.card ||
                      //   SmartpayTwelve.card.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpayTwelve.card} />
                  </div>
                  <hr />
                  <div className={styles['pm-container']}>
                    <img src={bankSrc} alt="Bank" width="32" height="20" />
                    <Toggle
                      checked={SmartpayTwelve.bank?.status === 'On'}
                      // disabled={
                      //   !SmartpayTwelve.bank ||
                      //   SmartpayTwelve.bank.status === 'Off'
                      // }
                      disabled
                      containerClassName={styles['toggler-container']}
                    />
                  </div>
                  <div className={styles['fee-box']}>
                    <h4>{t('settings.payment-options.smartpay-fee')}</h4>
                    <PlanFeeValue planFee={SmartpayTwelve.bank} />
                  </div>
                </div>
              </div>
              <div className={styles.actions}>
                <div className={styles['no-fee']} />
                <Button
                  className={styles.action}
                  size="small"
                  label={t('settings.payment-options.apply-changes')}
                  disabled
                />
              </div>
            </form>
            <p className={styles.note}>{t('settings.payment-options.note')}</p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PaymentOptionsScreen;
