import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useViewMode from '../../hooks/use-view-mode';

import srcBack from '../../assets/icon-back-squared.svg';

import styles from './Header.module.scss';

const Header = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation('translation');
  const currentLang = i18n?.language || 'ja';
  const { mode, merchant } = useViewMode();

  return (
    <header className={cx(styles.header, className)}>
      {mode === 'corporate-merchant' && (
        <Link to="/c/branches">
          <img
            className={styles['btn-close']}
            src={srcBack}
            alt="Back"
            width={24}
            height={24}
          />
        </Link>
      )}
      {merchant && (
        <>
          <h1>{merchant?.name}</h1>
          <span className={styles.id}>{merchant?.id}</span>
        </>
      )}
      <div className={styles.right}>
        <button
          className={styles['lang-switcher']}
          type="button"
          onClick={() =>
            i18n.changeLanguage(currentLang.startsWith('ja') ? 'en' : 'ja')
          }
        >
          {currentLang.startsWith('ja') ? 'English' : '日本語'}
        </button>
      </div>
    </header>
  );
};

export default Header;
