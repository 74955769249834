import cx from 'classnames';
import React, {
  FormEvent,
  ForwardedRef,
  RefCallback,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RefCallBack } from 'react-hook-form';
import IconCheck from '../../assets/icon-checked-20.svg';
import IconCopyGray from '../../assets/icon-content-copy-gray.svg';
import IconCopy from '../../assets/icon-content-copy.svg';
import IconEyeClosed from '../../assets/icon-eye-closed.svg';
import IconEyeOpened from '../../assets/icon-eye-opened.svg';
import IconSearch from '../../assets/icon-search.svg';
import IconZip from '../../assets/icon-zip.svg';
import Popup from '../Popup/Popup';
import styles from './TextInput.module.scss';
import { MaskitoOptions } from '@maskito/core';
import { useMaskito } from '@maskito/react';
import { useTranslation } from 'react-i18next';

const emptyFn = () => {};

export type IconType = 'none' | 'zip' | 'copy' | 'search';
export type IconColor = 'default' | 'gray';

const getIconSrc = (type: IconType, color: IconColor = 'default') => {
  switch (type) {
    case 'zip':
      return IconZip;
    case 'copy':
      return color === 'gray' ? IconCopyGray : IconCopy;
    case 'search':
      return IconSearch;
    default:
      return undefined;
  }
};

type TextInputType = 'text' | 'email' | 'password' | 'number' | 'date';

export interface TextInputProps {
  id?: string;
  type?: TextInputType;
  variant?: 'primary' | 'secondary' | 'clean';
  leftIcon?: IconType;
  rightIcon?: IconType;
  rightIconColor?: IconColor;
  rightText?: string;
  onRightIconClick?: () => void;
  label?: string;
  labelCaption?: string;
  'aria-label'?: string;
  placeholder?: string;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
  maxLength?: number;
  wrapperStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  style?: React.CSSProperties;
  name: string;
  autoFocus?: boolean;
  className?: string;
  autoComplete?: string;
  pattern?: string;
  updatePairs?: Function;
  maskOption?: MaskitoOptions;
  errorMessage?: string;
  showErrorWithoutMessage?: boolean;
  ref?: RefCallBack;
  maskedContent?: boolean;
  size?: number | undefined;
  inputRef?: ForwardedRef<HTMLInputElement>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setRef = (ref: ForwardedRef<any> | undefined, element: any) => {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(element);
  } else if (typeof ref === 'object') {
    // eslint-disable-next-line no-param-reassign
    ref.current = element;
  }
};

const MaskedInput = ({
  maskOption,
  inputRef,
  ...restProps
}: TextInputProps) => {
  const maskitoRefCallback = useMaskito({
    options: maskOption,
  });

  const refCallback: RefCallback<HTMLInputElement> = useCallback(
    (element) => {
      setRef(maskitoRefCallback, element);
      setRef(inputRef, element);
    },
    [inputRef, maskitoRefCallback]
  );

  return <input ref={refCallback} {...restProps} />;
};

const WrappedInput = ({
  maskOption,
  inputRef,
  size,
  ...restProps
}: TextInputProps) => {
  if (maskOption) {
    return (
      <MaskedInput maskOption={maskOption} inputRef={inputRef} {...restProps} />
    );
  }

  return <input ref={inputRef} {...(size ? { size } : null)} {...restProps} />;
};

const Icon = ({
  type,
  color = 'default',
  width = 16,
  value = '',
  onClick = emptyFn,
}: {
  type: IconType;
  color?: IconColor;
  width?: number;
  value?: string;
  onClick?: () => void;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const height = width;
  const icon = (
    <img
      className={cx(styles.icon, styles[type])}
      src={getIconSrc(type, color)}
      width={width}
      height={height}
      alt=""
      loading="lazy"
    />
  );

  return type === 'copy' && value ? (
    <Popup
      direction="up"
      content={
        <div className={styles['popup-content']}>
          {isCopied ? (
            <div className={styles['copied-content']}>
              <img
                src={IconCheck}
                width={20}
                height={20}
                alt=""
                loading="eager"
              />
              <p>Copied</p>
            </div>
          ) : (
            <p>Copy</p>
          )}
        </div>
      }
      trigger={
        <div onMouseLeave={() => setIsCopied(false)}>
          <CopyToClipboard
            text={value}
            onCopy={() => {
              onClick();
              setIsCopied(true);
            }}
          >
            {icon}
          </CopyToClipboard>
        </div>
      }
    />
  ) : (
    icon
  );
};

const usePasswordManagerHelper = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [passwordIconStyle, setPasswordIconStyle] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        if (
          window
            .getComputedStyle(inputRef.current)
            .getPropertyValue('background-image') !== 'none'
        ) {
          setPasswordIconStyle(styles['password-management']);
        }
      }
    }, 1000);
  }, [inputRef]);

  return { inputRef, passwordIconStyle };
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type = 'text',
      variant = 'primary',
      leftIcon = 'none',
      rightIcon = 'none',
      rightIconColor = 'default',
      onRightIconClick = emptyFn,
      rightText = '',
      label = '',
      labelCaption = '',
      placeholder = '',
      onInput = emptyFn,
      onChange = emptyFn,
      onBlur = emptyFn,
      value,
      disabled = false,
      readOnly = false,
      onFocus = emptyFn,
      maxLength,
      wrapperStyles,
      inputStyles,
      name,
      autoFocus = false,
      className,
      pattern,
      autoComplete,
      updatePairs,
      maskOption,
      maskedContent = false,
      errorMessage,
      size = 0,
      showErrorWithoutMessage = false,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('translation');
    const { inputRef: passwordManagerInputRef, passwordIconStyle } =
      usePasswordManagerHelper();
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const passwordInputType = isPasswordVisible ? 'text' : 'password';

    const _onChange = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        if (updatePairs) {
          const { validity, name: _name, value: _value } = event.currentTarget;

          /**
           * If the date input received invalid input(ex: 1981/06/100) via keyboard
           * The change event will still trigger, but the value will be empty string
           * Use validity state to check is the input valid at the moment
           */
          if (type === 'date' && validity && !validity.valid) {
            return;
          }

          updatePairs({
            [_name]: _value,
          });
        }

        onChange?.(event);
      },
      [onChange, type, updatePairs]
    );

    const _onInput = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        onInput?.(event);
        _onChange(event);
      },
      [_onChange, onInput]
    );

    const _onFocus = (event: React.FormEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    };

    const _onBlur = (event: React.FormEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    };

    const onTogglePassword = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    let inputType: string;

    if (type === 'password') {
      inputType = passwordInputType;
    } else {
      inputType = maskedContent ? 'password' : type;
    }

    const refCallback: RefCallback<HTMLInputElement> = useCallback(
      (element) => {
        setRef(passwordManagerInputRef, element);
        setRef(ref, element);
      },
      [passwordManagerInputRef, ref]
    );

    return (
      <div
        className={cx(
          styles['input-box'],
          isFocused ? styles.focused : '',
          className
        )}
        style={wrapperStyles}
      >
        {label && (
          <label
            className={errorMessage && styles.error}
            htmlFor={`ip_${name}`}
          >
            {label}
            <span>{labelCaption}</span>
          </label>
        )}
        <div
          className={cx(
            styles['input-wrapper'],
            styles[variant],
            disabled && styles.disabled,
            errorMessage && styles.error
          )}
        >
          {leftIcon !== 'none' && <Icon type={leftIcon} value={value} />}
          <WrappedInput
            id={`ip_${name}`}
            style={inputStyles}
            type={inputType as TextInputType}
            onInput={_onInput}
            onChange={_onChange}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            className={cx(
              label ? '' : styles.raw,
              styles[name],
              styles[variant],
              leftIcon !== 'none' ? styles['left-icon'] : undefined,
              rightIcon !== 'none' ? styles['right-icon'] : undefined
            )}
            onFocus={_onFocus}
            onBlur={_onBlur}
            maxLength={maxLength}
            name={name}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            pattern={pattern}
            maskOption={maskOption}
            inputRef={refCallback}
            size={size}
            {...props}
          />
          {type === 'password' && (
            <img
              className={cx(styles.eye, passwordIconStyle)}
              src={isPasswordVisible ? IconEyeOpened : IconEyeClosed}
              width={24}
              height={24}
              alt=""
              loading="lazy"
              onClick={onTogglePassword}
            />
          )}
          {rightIcon !== 'none' && (
            <Icon
              type={rightIcon}
              color={rightIconColor}
              value={value}
              onClick={onRightIconClick}
            />
          )}
          {rightText && (
            <span className={styles['right-text']}>{rightText}</span>
          )}
        </div>
        {!showErrorWithoutMessage && errorMessage && (
          <div className={styles['error-wrapper']}>
            <p>{t([`error.${errorMessage}`, errorMessage])}</p>
          </div>
        )}
      </div>
    );
  }
);

export default TextInput;
