import { Link } from '@reach/router';
import { useState } from 'react';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';

import DataToggle from '../Toggle/DataToggle';
import OutsideClickHandler from 'react-outside-click-handler';
import ProfilePopup, { PROFILE_POPUP_SCREEN } from '../Popup/ProfilePopup';
import { SideBarItem, SideBarLink } from '../Aside/Aside';

import logo from '../../assets/logo-symbol.svg';
import srcProfile from '../../assets/icon-sidebar-profile.svg';
import srcInquiry from '../../assets/icon-sidebar-inquiry.svg';

import styles from './CorporateAside.module.scss';

const CorporateAside = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  return (
    <aside className={styles.aside}>
      <Link to="/c/branches">
        <img src={logo} width={24} height={24} alt="Smartpay" />
      </Link>
      <nav>
        <section />
        <section className={styles.bot}>
          <div className={styles.block}>
            <SideBarLink
              id="menu_inquiry"
              to="/inquiry"
              src={srcInquiry}
              active_src={srcInquiry}
            />
          </div>
          <div className={styles.block}>
            <button
              id="btn_profile"
              type="button"
              className={cx(styles.profile)}
              onClick={() => setIsShowPopup(!isShowPopup)}
            >
              <SideBarItem id="menu_profile" src={srcProfile} />
            </button>
            {isShowPopup && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  Mixpanel.trackAction({
                    screen: PROFILE_POPUP_SCREEN,
                    action: 'Click',
                    itemName: 'Dismiss',
                  });

                  if (isShowPopup) {
                    setIsShowPopup(false);
                  }
                }}
              >
                <ProfilePopup className={styles['profile-menu']} />
              </OutsideClickHandler>
            )}
          </div>
          <DataToggle className={styles.toggler} condensed />
        </section>
      </nav>
    </aside>
  );
};

export default CorporateAside;
