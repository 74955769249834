import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Mixpanel from '@smartpay/mixpanel';

import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { BRANCHE_CREATION_SCREEN } from '../../constants';
import { useCreateBranchMutation } from '../../services/setting';

import styles from './CreateBranchModal.module.scss';

type CreateBranchFormInput = {
  name: string;
};

const CreateBranchModal = ({ hide }: { hide: () => void }) => {
  const { t } = useTranslation('translation');
  const [createBranch] = useCreateBranchMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<CreateBranchFormInput>();

  const onSubmit = async (data: CreateBranchFormInput) => {
    Mixpanel.trackAction({
      screen: BRANCHE_CREATION_SCREEN,
      action: 'Click',
      itemName: 'Create Branch',
    });

    try {
      const result = await createBranch(data);

      if ('error' in result) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error((result.error as any).data || 'unexpected_error');
      }

      hide();
    } catch (error) {
      // set error msg
    }
  };

  return (
    <div className={styles['modal-background']}>
      <div className={cx(styles.modal)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h3 id="create-branch-modal-title">
              {t('create-branch-modal.title')}
            </h3>
          </div>
          <div className={styles.body}>
            <fieldset>
              <TextInput
                autoFocus
                label={t('create-branch-modal.branch-name.label')}
                {...register('name')}
              />
            </fieldset>
          </div>
          <div className={styles['modal-footer']}>
            <Button
              id="btn_create_branch_cancel"
              label={t('cancel-btn')}
              size="small"
              variant="outline"
              onClick={() => {
                Mixpanel.trackAction({
                  screen: BRANCHE_CREATION_SCREEN,
                  action: 'Click',
                  itemName: 'Create Branch Cancel',
                });

                hide();
              }}
            />
            <Button
              type="submit"
              id="btn_create_branch_confirm"
              label={t('create-branch-modal.button.create')}
              size="small"
              disabled={!isValid}
              processing={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBranchModal;
