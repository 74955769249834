import Toggle from '../../components/Toggle/Toggle';

import styles from './TogglerRow.module.scss';

export type PermissionValue =
  | 'viewer'
  | 'editor'
  | 'viewer-pii'
  | 'editor-pii'
  | '';

interface PermissionRowProps {
  label: string;
  desc?: string;
  name: string;
  value?: PermissionValue;
  setValue?: (arg: PermissionValue) => void;
  onChange?: (arg: PermissionValue) => void;
  disabled?: boolean;
  pii?: boolean;
}

const PermissionRow = ({
  label,
  desc,
  name,
  value = '',
  setValue,
  onChange,
  disabled = false,
  pii = false,
}: PermissionRowProps) => {
  return (
    <div className={styles.container}>
      <label>
        <span className={styles.label}>{label}</span>
        {!!desc && <span className={styles.desc}>{desc}</span>}
      </label>
      <div className={styles.end}>
        {value && (
          <select
            onChange={(event) => {
              const v = event.target.value as PermissionValue;

              setValue?.(v);
              onChange?.(v);
            }}
          >
            <option value="viewer" selected={value === 'viewer'}>
              Viewer
            </option>
            {pii && (
              <option value="viewer-pii" selected={value === 'viewer-pii'}>
                Viewer + PII
              </option>
            )}
            <option value="editor" selected={value === 'editor'}>
              Editor
            </option>
            {pii && (
              <option value="editor-pii" selected={value === 'editor-pii'}>
                Editor + PII
              </option>
            )}
          </select>
        )}
        <Toggle
          name={name}
          checked={!!value}
          controlled
          onChange={(v) => {
            setValue?.(v ? 'viewer' : '');
            onChange?.(v ? 'viewer' : '');
          }}
          disabled={disabled}
          containerClassName={styles['toggle-container']}
        />
      </div>
    </div>
  );
};

export default PermissionRow;
