import Toggle from '../../components/Toggle/Toggle';

import styles from './TogglerRow.module.scss';

interface TogglerRowProps {
  label: string;
  desc?: string;
  name: string;
  value?: boolean;
  setValue?: (arg: boolean) => void;
  onChange?: (event: boolean) => void;
  disabled?: boolean;
}

const TogglerRow = ({
  label,
  desc,
  name,
  value = false,
  setValue,
  onChange,
  disabled = false,
}: TogglerRowProps) => {
  return (
    <div className={styles.container}>
      <label>
        <span className={styles.label}>{label}</span>
        {!!desc && <span className={styles.desc}>{desc}</span>}
      </label>
      <div className={styles.end}>
        <Toggle
          name={name}
          checked={value}
          controlled
          onChange={(v) => {
            setValue?.(v);
            onChange?.(v);
          }}
          disabled={disabled}
          containerClassName={styles['toggle-container']}
        />
      </div>
    </div>
  );
};

export default TogglerRow;
